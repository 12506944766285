<template>
  <el-aside width="200px" class="side-menu">
    <el-menu
      :default-active="activeMenu"
      class="el-menu-vertical-demo"
      @select="handleMenuSelect"
      background-color="#FFFFFF"
      text-color="#000000"
      active-text-color="#3b82f6"
      collapse-transition
    >
  

      <el-menu-item index="users" v-if="hasPermission('view_users')" class="menu-item">
        <User class="menu-icon" style="width: 18px; height: 18px;" />
        <span>用户管理</span>
      </el-menu-item>
      <el-menu-item index="roles" v-if="hasPermission('view_roles')" class="menu-item">
        <Tools class="menu-icon" style="width: 18px; height: 18px;" />
        <span>角色管理</span>
      </el-menu-item>
      <el-menu-item index="permissions" v-if="hasPermission('view_permissions')" class="menu-item">
        <Lock class="menu-icon" style="width: 18px; height: 18px;" />
        <span>权限管理</span>
      </el-menu-item>
      <el-menu-item index="ip" v-if="hasPermission('view_ip')" class="menu-item">
        <TurnOff class="menu-icon" style="width: 18px; height: 18px;" />
        <span>IP管理</span>
      </el-menu-item>

      <el-menu-item  v-if="hasPermission('view_example')" class="menu-item" @click="toggleSubmenu('example')">
        <Folder class="menu-icon" style="width: 18px; height: 18px;" />
        <span>线索管理</span>
        <el-icon v-if="activeSubmenu === 'example'" style="margin-left:auto;">
          <ArrowDown />
        </el-icon>
        <el-icon v-else style="margin-left:auto;">
          <ArrowRight />
        </el-icon>
      </el-menu-item>

      <el-menu-item v-if="activeSubmenu === 'example' && hasPermission('view_clues')" index="clues" class="submenu-item">
        <Upload class="menu-icon" style="width: 18px; height: 18px;" />
        <span>线索管理</span>
      </el-menu-item>
      <el-menu-item v-if="activeSubmenu === 'example' && hasPermission('view_clues_Batch')" index="BatchOperations" class="submenu-item">
        <Rank class="menu-icon" style="width: 18px; height: 18px;" />
        <span>批量操作</span>
      </el-menu-item>

      <el-menu-item v-if="activeSubmenu === 'example' && hasPermission('view_clues_pool')" index="cluespool" class="submenu-item">
        <Finished class="menu-icon" style="width: 18px; height: 18px;" />
        <span>线索池</span>
      </el-menu-item>




      <el-menu-item  v-if="hasPermission('view_kscrm')" class="menu-item" @click="toggleSubmenu('kscrm')">
        <Folder class="menu-icon" style="width: 18px; height: 18px;" />
        <span>快手CRM</span>
        <el-icon v-if="activeSubmenu === 'kscrm'" style="margin-left:auto;">
          <ArrowDown />
        </el-icon>
        <el-icon v-else style="margin-left:auto;">
          <ArrowRight />
        </el-icon>
      </el-menu-item>

      <el-menu-item v-if="activeSubmenu === 'kscrm' && hasPermission('view_kscrmxs')" index="KsXs" class="submenu-item">
        <Upload class="menu-icon" style="width: 18px; height: 18px;" />
        <span>快手线索</span>
      </el-menu-item>
      

      <el-menu-item v-if="activeSubmenu === 'kscrm' && hasPermission('view_kscrmsz')" index="kscrms" class="submenu-item">
        <Finished class="menu-icon" style="width: 18px; height: 18px;" />
        <span>KSCRM设置</span>
      </el-menu-item>



      <el-menu-item  v-if="hasPermission('view_kscrm')" class="menu-item" @click="toggleSubmenu('txcrm')">
        <Folder class="menu-icon" style="width: 18px; height: 18px;" />
        <span>腾讯CRM</span>
        <el-icon v-if="activeSubmenu === 'txcrm'" style="margin-left:auto;">
          <ArrowDown />
        </el-icon>
        <el-icon v-else style="margin-left:auto;">
          <ArrowRight />
        </el-icon>
      </el-menu-item>

      <el-menu-item v-if="activeSubmenu === 'txcrm' && hasPermission('view_kscrmxs')" index="QqXs" class="submenu-item">
        <Upload class="menu-icon" style="width: 18px; height: 18px;" />
        <span>腾讯线索</span>
      </el-menu-item>
      

      <el-menu-item v-if="activeSubmenu === 'txcrm' && hasPermission('view_kscrmsz')" index="Qqcrms" class="submenu-item">
        <Finished class="menu-icon" style="width: 18px; height: 18px;" />
        <span>腾讯CRM设置</span>
      </el-menu-item>





      <el-menu-item index="logout" @click="handleLogout" class="menu-item">
        <SwitchButton class="menu-icon" style="width: 18px; height: 18px;" />
        <span>退出登录</span>
      </el-menu-item>
    </el-menu>
  </el-aside>
</template>


<script>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { User, Tools, Lock, TurnOff, SwitchButton, Folder, Upload, Rank, Finished, ArrowRight, ArrowDown } from '@element-plus/icons-vue';

export default {
  components: {
    User,
    Tools,
    Lock,
    TurnOff,
    SwitchButton,
    Folder,
    Upload,
    Rank,
    Finished,
    ArrowRight,
    ArrowDown
  },
  setup(_, { emit }) {
    const store = useStore();
    const router = useRouter();
    const activeMenu = ref('example,kscrm');

    const activeSubmenu = ref('');

    const handleMenuSelect = (index) => {
      if (index !== 'logout') {
        requestAnimationFrame(() => {
          router.push(`/dashboard/${index}`);
          const tabLabels = {
            users: '用户管理',
            roles: '角色管理',
            permissions: '权限管理',
            ip: 'IP管理',
            media: '媒体库（上传下载）',
            breakpoint: '断点续传',
            customer: '客户列表（资源示例）',
          };
          emit('addTab', index, tabLabels[index]);
        });
      }
    };

    const handleLogout = () => {
      store.dispatch('logout').then(() => {
        router.push('/login');
      });
    };

    const hasPermission = (permission) => {
      return store.state.permissions.includes(permission);
    };

    const toggleSubmenu = (submenu) => {
      if (activeSubmenu.value === submenu) {
        activeSubmenu.value = '';
      } else {
        activeSubmenu.value = submenu;
      }
    };

    return {
      activeMenu,
      activeSubmenu,
      handleMenuSelect,
      handleLogout,
      hasPermission,
      toggleSubmenu,
    };
  },
};
</script>

<style scoped>
.side-menu {
  background-color: #ffffff;
  color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  position: fixed;
  width: 200px;
  top: 64px;
  border-right: 1px solid #dcdcdc;
}

.menu-item,
.submenu-item {
  display: flex;
  align-items: center;
}

.menu-icon {
  font-size: 18px;
  margin-right: 10px;
}

.el-menu-item:focus,
.el-menu-item:hover,
.el-submenu__title:hover {
  background-color: #3b82f6 !important;
  color: aliceblue;
}
</style>
