<template>
  <div>
    <p style="color: #172b4d; font-weight: 900; font-size: 24px;">
      <el-icon><Cpu /></el-icon> 批量操作管理
    </p>

    <el-form :inline="true" :model="filters" class="filter-form">
      <!-- 创建时间过滤 -->
      <el-form-item label="创建时间">
        <el-date-picker
          v-model="filters.created_at"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>

      <!-- 批次号过滤 -->
      <el-form-item label="批次号">
        <el-input v-model="filters.batch_number" placeholder="请输入批次号"></el-input>
      </el-form-item>

      <!-- 操作类型过滤 -->
      <el-form-item label="操作类型">
        <el-select v-model="filters.operation_type" placeholder="请选择操作类型">
          <el-option label="导入" value="import"></el-option>
          <el-option label="更新" value="update"></el-option>
          <el-option label="去重" value="deduplicate"></el-option>
        </el-select>
      </el-form-item>

      <!-- 任务状态过滤 -->
      <el-form-item label="任务状态">
        <el-select v-model="filters.status" placeholder="请选择任务状态">
          <el-option label="待处理" value="pending"></el-option>
          <el-option label="处理中" value="processing"></el-option>
          <el-option label="已完成" value="completed"></el-option>
          <el-option label="失败" value="failed"></el-option>
        </el-select>
      </el-form-item>

      <!-- 查询与重置按钮 -->
      <el-form-item>
        <el-button type="primary" @click="fetchBatchOperations">查询</el-button>
        <el-button @click="resetFilters">重置</el-button>
      </el-form-item>

      <el-form-item>
        <el-button v-if="hasPermission('import_batch_operations')" type="primary" @click="openImportDrawer">导入</el-button>
        <el-button v-if="hasPermission('update_batch_operations')" type="success" @click="openUpdateDrawer">更新</el-button>
        <el-button v-if="hasPermission('deduplicate_batch_operations')" type="info" @click="openDeduplicateDrawer">去重</el-button>
        <el-button v-if="hasPermission('delete_batch_operations')" type="danger" @click="deleteSelected">删除</el-button>
      </el-form-item>
    </el-form>

  
      <el-table :data="batchOperations" ref="batchTable" style="width: 100%" @selection-change="handleSelectionChange" >
        <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="id" label="编号" width="60"></el-table-column>
      <el-table-column label="批次号" prop="batch_number"></el-table-column>
      <el-table-column label="任务描述" prop="description"></el-table-column>
      <el-table-column prop="total_rows" label="总行数"></el-table-column>
      <el-table-column prop="processed_rows" label="已处理行数"></el-table-column>
      <el-table-column prop="inserted_rows" label="成功写入"></el-table-column>
      <el-table-column prop="filtered_rows" label="过滤/重复/失败" width="155"></el-table-column>
      <el-table-column prop="exported_rows" label="去重之后的数据" width="155"></el-table-column>
      <el-table-column label="任务状态">
        <template #default="{ row }">
          <span :style="{ color: row.status === 'completed' ? 'green' : '' }">
            <span v-if="row.status === 'pending'">待处理</span>
            <span v-else-if="row.status === 'processing'">处理中</span>
            <span v-else-if="row.status === 'completed'">已完成</span>
            <span v-else-if="row.status === 'failed'">失败</span>
          </span>
        </template>
      </el-table-column>

      <el-table-column label="执行进度">
        <template #default="{ row }">
          <span :style="{ color: row.progress === 100 ? 'green' : '' }">
            {{ row.progress }}%
          </span>
        </template>
      </el-table-column>

      <el-table-column label="操作类型">
        <template #default="{ row }">
          <span v-if="row.operation_type === 'import'">导入</span>
          <span v-else-if="row.operation_type === 'update'">更新</span>
          <span v-else-if="row.operation_type === 'deduplicate'">去重</span>
        </template>
      </el-table-column>

      <el-table-column prop="created_at" label="创建时间"></el-table-column>
      <el-table-column prop="updated_at" label="更新时间"></el-table-column>
    
     <!-- 操作列 -->
 <el-table-column label="操作" width="180">
    <template #default="{ row }">
      <el-button v-if="hasPermission('edit_batch_operations')" type="primary" @click="editTask(row)" size="mini">编辑</el-button>
      <el-button type="success" v-if="hasPermission('dow_batch_operations')" @click="downloadTask(row)" size="mini">下载</el-button>
    </template>
  </el-table-column>
    
    
    
    
    </el-table>






    <el-pagination
      background
      layout="prev, pager, next, total"
      :total="total"
      :page-size="limit"
      v-model:current-page="page"
      @current-change="fetchBatchOperations"
    ></el-pagination>



    <!-- 导出配置对话框 -->
    <el-drawer
      title="导出批量操作记录"
      :model-value="exportDrawerVisible"
      size="50%"
      direction="rtl"
      @close="exportDrawerVisible = false"
    >
      <el-form :model="exportOptions">
        <el-form-item label="选择字段导出范围">
          <el-checkbox-group v-model="exportOptions.fields">
            <el-checkbox v-for="field in availableFields" :label="field.value" :key="field.value">
              {{ field.label }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>

        <el-form-item label="选择导出文件类型">
          <el-radio-group v-model="exportOptions.file_type">
            <el-radio label="xls">xls</el-radio>
            <el-radio label="csv">csv</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>

      <div style="text-align: right; margin-top: 20px;">
        <el-button @click="exportDrawerVisible = false">取消</el-button>
        <el-button type="primary" @click="exportData">导出</el-button>
      </div>
    </el-drawer>

    <!-- 导入线索 -->
    <el-drawer
      title="导入线索"
      :model-value="importDrawerVisible"
      size="30%"
      direction="rtl"
      @close="closeImportDrawer"
    >
      <!-- 添加说明和下载链接 -->
      <div style="margin-bottom: 20px;">
        <p style="color: #b5b5b5; font-size: 13px;">
          请按照数据模板的格式准备要导入的数据。 
          <a href="https://serve.cdnusdt.com/xls/线索导入模板.xlsx" download>点击下载《线索导入模板》</a>
        </p>
        <p style="color: #b5b5b5; font-size: 13px;">导入文件勿超过2MB（约10,000条数据）</p>
      </div>

      <el-upload
        ref="upload"
        action=""
        :auto-upload="false"
        v-model:file-list="fileList"
        :before-upload="beforeUpload"
        :on-remove="handleRemove"
      >
        <template #trigger>
          <el-button type="primary">选择文件</el-button>
        </template>
        <template #tip>
          <div class="el-upload__tip">csv 格式  <a style="color: red">大数据文件请转换 csv格式在导入</a></div>
        </template>
      </el-upload>

      <div style="text-align: right; margin-top: 20px;">
        <el-button @click="closeImportDrawer">取消</el-button>
        <el-button type="primary" :loading="importing" @click="importData">导入</el-button>
      </div>
    </el-drawer>

    <el-drawer
      title="更新数据"
      :model-value="updateDrawerVisible"
      size="30%"
      direction="rtl"
      @close="closeUpdateDrawer"
    >
      <!-- 复用导入线索的文件上传部分 -->
      <div style="margin-bottom: 20px;">
        <p style="color: #b5b5b5; font-size: 13px;">
          请按照数据模板的格式准备要导入的数据。 
          <a href="https://serve.cdnusdt.com/xls/更新数据模板.xlsx" download>点击下载《更新数据模板》</a>
        </p>
        <p style="color: #b5b5b5; font-size: 13px;">导入文件勿超过2MB（约10,000条数据）</p>
      </div>
      <el-upload
        ref="upload"
        action=""
        :auto-upload="false"
        v-model:file-list="fileList"
        :before-upload="beforeUpload"
        :on-remove="handleRemove"
      >
        <template #trigger>
          <el-button type="primary">选择文件</el-button>
        </template>
        <template #tip>
          <div class="el-upload__tip">支持csv 格式 <a style="color: red">大数据文件请转换 csv格式在导入</a></div>
        </template>
      </el-upload>
      <div style="text-align: right; margin-top: 20px;">
        <el-button @click="closeUpdateDrawer">取消</el-button>
        <el-button type="primary" :loading="updating" @click="updateData">更新</el-button>
      </div>
    </el-drawer>

    <el-drawer
      title="去重数据"
      :model-value="deduplicateDrawerVisible"
      size="30%"
      direction="rtl"
      @close="closeDeduplicateDrawer"
    >
      <!-- 复用导入线索的文件上传部分 -->
      <div style="margin-bottom: 20px;">
        <p style="color: #b5b5b5; font-size: 13px;">
          请按照数据模板的格式准备要导入的数据。 
          <a href="https://serve.cdnusdt.com/xls/去重数据模板.xlsx" download>点击下载《去重数据模板》</a>
        </p>
        <p style="color: #b5b5b5; font-size: 13px;">导入文件勿超过2MB（约10,000条数据）</p>
      </div>
      <el-upload
        ref="upload"
        action=""
        :auto-upload="false"
        v-model:file-list="fileList"
        :before-upload="beforeUpload"
        :on-remove="handleRemove"
      >
        <template #trigger>
          <el-button type="primary">选择文件</el-button>
        </template>
        <template #tip>
          <div class="el-upload__tip">支持Csv 格式 <a style="color: red">大数据文件请转换 csv格式在导入</a></div>
        </template>
      </el-upload>
      <div style="text-align: right; margin-top: 20px;">
        <el-button @click="closeDeduplicateDrawer">取消</el-button>
        <el-button type="primary" :loading="deduplicating" @click="deduplicateData">去重</el-button>
      </div>
    </el-drawer>

  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import axios from '../axiosInstance';
import { ElMessage, ElMessageBox } from 'element-plus';
import { Cpu } from '@element-plus/icons-vue';

export default {
  components: {
    Cpu,
  },
  setup() {
    const batchOperations = ref([]);
    const selectedIds = ref([]);
    const total = ref(0);
    const page = ref(1);
    const limit = ref(10);
    const filters = ref({
      operation_name: '',
      operation_status: '',
    });
    const exportDrawerVisible = ref(false);
    const exportOptions = ref({
      fields: [
        'id', 'operation_name', 'operation_number', 'data_total', 
        'success_count', 'failure_count', 'operation_type', 'operation_status', 'created_at'
      ],
      file_type: 'xls',
    });
    const availableFields = [
      { label: '编号', value: 'id' },
      { label: '操作描述', value: 'operation_name' },
      { label: '操作批号', value: 'operation_number' },
      { label: '数据总量', value: 'data_total' },
      { label: '执行成功', value: 'success_count' },
      { label: '执行失败', value: 'failure_count' },
      { label: '操作类型', value: 'operation_type' },
      { label: '操作状态', value: 'operation_status' },
      { label: '创建时间', value: 'created_at' },
    ];

    const store = useStore();

    const fetchBatchOperations = async () => {
      try {
        const response = await axios.get('/api/apifoadmin/batch-operations', {
          params: {
            page: page.value,
            limit: limit.value,
            filter: filters.value,
          },
        });
        batchOperations.value = response.data.data;
        total.value = response.data.pagination.total;
      } catch (error) {
        ElMessage.error('无法获取批量操作记录: ' + error.message);
      }
    };

    const resetFilters = () => {
      filters.value = {
        operation_name: '',
        operation_status: '',
      };
      fetchBatchOperations();
      ElMessage.success('筛选条件已重置');
    };

    const exportData = async () => {
      try {
        const response = await axios({
          url: '/api/apifoadmin/batch-operations/export',
          method: 'POST',
          responseType: 'blob',
          data: {
            fields: exportOptions.value.fields,
            file_type: exportOptions.value.file_type,
          },
        });

        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        const link = document.createElement('a');
        const url = window.URL.createObjectURL(blob);

        link.href = url;
        link.setAttribute('download', `batch_operations_export.${exportOptions.value.file_type}`);
        document.body.appendChild(link);
        link.click();

        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);

        exportDrawerVisible.value = false;
        ElMessage.success('数据导出成功');
      } catch (error) {
        ElMessage.error('导出数据失败: ' + error.message);
      }
    };

    const hasPermission = (permission) => {
      return store.state.permissions.includes(permission);
    }

    const importDrawerVisible = ref(false);
    const updateDrawerVisible = ref(false);
    const deduplicateDrawerVisible = ref(false);
    const fileList = ref([]);

    const beforeUpload = (file) => {
      const isXls = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      const isCsv = file.type === 'text/csv';
      if (!isXls && !isCsv) {
        ElMessage.error('只能上传 xls 或 csv 格式的文件');
        return false;
      }
      return true;
    };

    const handleRemove = (file) => {
      fileList.value = fileList.value.filter(f => f.uid !== file.uid);
    };

    const importing = ref(false);
    const updating = ref(false);
    const deduplicating = ref(false);

   
    const importData = async () => {
  // 检查是否选择了文件
  if (!fileList.value || fileList.value.length === 0) {
    ElMessage.error('请先选择文件');
    return;
  }

  importing.value = true; // 设置上传状态
  const formData = new FormData();
  formData.append('file', fileList.value[0].raw);

  try {
    // 调用指定接口上传文件，并添加 token
    const response = await axios.post('https://ld.cdnusdt.com/import_data', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
         'token': 'D924EB5AE911E422811333D42B452B24'
      },
    });

    // 检查上传响应的状态
    if (response.status === 200 || response.status === 201) {
      ElMessage.success('数据上传成功，文件正在后台处理中，请稍后查看结果');
      closeImportDrawer(); // 关闭导入窗口
      fetchBatchOperations(); // 刷新列表
      window.location.reload(); // 刷新页面
    } else {
      ElMessage.error('数据上传成功，但服务器未返回预期的响应');
    }
  } catch (error) {
    // 检查并处理错误消息
    if (error.response && error.response.data && error.response.data.message) {
      ElMessage.error('数据导入失败: ' + error.response.data.message);
    } else {
      ElMessage.error('数据导入失败: ' + error.message);
    }
  } finally {
    importing.value = false; // 重置上传状态
  }
};


const updateData = async () => {
  if (!fileList.value || fileList.value.length === 0) {
    ElMessage.error('请先选择文件');
    return;
  }

  updating.value = true;
  const formData = new FormData();
  formData.append('file', fileList.value[0].raw);

  try {
    // 修改接口 URL
    const response = await axios.post('https://lup.cdnusdt.com/import_data', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    // 根据返回的状态显示提示信息
    if (response.data.status === '文件上传成功') {
      ElMessage.success('数据更新成功，文件正在后台处理中，请稍后查看结果');
      closeUpdateDrawer();
      fetchBatchOperations();
      window.location.reload(); // 上传成功后刷新页面
    } else {
      ElMessage.error('数据更新失败: ' + response.data.error);
    }
  } catch (error) {
    ElMessage.error('数据更新失败: ' + error.message);
  } finally {
    updating.value = false;
  }
};


    /*const deduplicateData = async () => {
      if (!fileList.value || fileList.value.length === 0) {
        ElMessage.error('请先选择文件');
        return;
      }

      deduplicating.value = true;
      const formData = new FormData();
      formData.append('file', fileList.value[0].raw);

      try {
        await axios.post('/api/apifoadmin/BatchOperationdeduplicate/deduplicate', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        ElMessage.success('数据去重成功，文件正在后台处理中，请稍后查看结果');
        closeDeduplicateDrawer();
        fetchBatchOperations();
        window.location.reload(); // 上传成功后刷新页面
      } catch (error) {
        ElMessage.error('数据去重失败: ' + error.message);
      } finally {
        deduplicating.value = false;
      }
    };*/



/*开始 */
const deduplicateData = async () => {
  if (!fileList.value || fileList.value.length === 0) {
    ElMessage.error('请先选择文件');
    return;
  }

  deduplicating.value = true;
  const formData = new FormData();
  formData.append('file', fileList.value[0].raw);

  try {
    const response = await axios.post('https://pyapi.cdnusdt.com/deduplicate', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'token': 'D924EB5AE911E422811333D42B452B24'
      },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        // 你可以在这里显示进度条，更新进度
        console.log(`上传进度: ${percentCompleted}%`);
      }
    });

    if (response.status === 200) {
      ElMessage.success('数据去重成功，文件正在后台处理中，请稍后查看结果');
      closeDeduplicateDrawer();
      fetchBatchOperations();
      // 不要强制刷新整个页面，可以调用需要刷新的函数
      fetchBatchOperations(); // 调用此方法刷新必要数据
    } else {
      ElMessage.error('数据去重失败: 服务器返回了错误状态码');
    }
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      ElMessage.error('数据去重失败: ' + error.response.data.error);
    } else {
      ElMessage.error('数据去重失败: ' + error.message);
    }
  } finally {
    deduplicating.value = false;
  }
};

/*结束 */




//批量删除
const handleSelectionChange = (val) => {
      selectedIds.value = val.map(item => item.id);
    };

    const deleteSelected = () => {
      if (selectedIds.value.length === 0) {
        ElMessage.warning('请选择要删除的记录');
        return;
      }

      ElMessageBox.confirm('此操作将永久删除选中的记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      }).then(async () => {
        try {
          await axios.post('/api/apifoadmin/batch-operationst/batch-delete', {
            ids: selectedIds.value,
          });
          ElMessage.success('批量删除成功');
          fetchBatchOperations(); // 刷新列表
          selectedIds.value = []; // 清空选择
        } catch (error) {
          ElMessage.error('批量删除失败: ' + error.message);
        }
      }).catch(() => {
        ElMessage.info('已取消删除');
      });
    };



    const editTask = async (row) => {
  try {
    const { value } = await ElMessageBox.prompt('编辑任务描述', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      inputValue: row.description,
      center: true,
    });

    await axios.post('/api/apifoadmin/batch-operationb/update-description', {
      id: row.id,
      description: value,
    });

    row.description = value;
    ElMessage.success('任务描述已更新');
  } catch (error) {
    if (error !== 'cancel') {
      ElMessage.error('更新任务描述失败: ' + error.message);
    }
  }
};


//下载
/*
const downloadTask = async (row) => {
    try {
        const response = await axios({
            url: `/api/apifoadmin/batch-operationbpi/download/${row.id}`,
            method: 'GET',
            responseType: 'blob',
        });

        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        const link = document.createElement('a');
        const url = window.URL.createObjectURL(blob);

        link.href = url;
        link.setAttribute('download', `${row.batch_number}.xlsx`); // 假设文件是 .xlsx 格式
        document.body.appendChild(link);
        link.click();

        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);

        ElMessage.success('文件下载成功');
    } catch (error) {
        ElMessage.error('文件下载失败: ' + error.message);
    }
};*/
const downloadTask = async (row) => {
    try {
        const response = await axios({
            url: `/api/apifoadmin/batch-operationbpi/download/${row.id}`,
            method: 'GET',
        });

        const downloadUrl = response.data.download_url;

        if (downloadUrl) {
            // 创建一个隐藏的链接用于下载
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', `${row.batch_number}`); // 设置下载文件名
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            ElMessage.success('文件下载成功');
        } else {
            ElMessage.error('文件下载失败: 无效的下载链接');
        }
    } catch (error) {
        ElMessage.error('文件下载失败: ' + error.message);
    }
};



    const closeImportDrawer = () => {
      importDrawerVisible.value = false;
      updateDrawerVisible.value = false;
      deduplicateDrawerVisible.value = false;
    };

    const closeUpdateDrawer = () => {
      importDrawerVisible.value = false;
      updateDrawerVisible.value = false;
      deduplicateDrawerVisible.value = false;
    };

    const closeDeduplicateDrawer = () => {
      importDrawerVisible.value = false;
      updateDrawerVisible.value = false;
      deduplicateDrawerVisible.value = false;
    };

    const openImportDrawer = () => {
      importDrawerVisible.value = true;
      updateDrawerVisible.value = false;
      deduplicateDrawerVisible.value = false;
    };

    const openUpdateDrawer = () => {
      importDrawerVisible.value = false;
      updateDrawerVisible.value = true;
      deduplicateDrawerVisible.value = false;
    };

    const openDeduplicateDrawer = () => {
      importDrawerVisible.value = false;
      updateDrawerVisible.value = false;
      deduplicateDrawerVisible.value = true;
    };

    onMounted(() => {
      fetchBatchOperations();
    });

    return {
      batchOperations,
      total,
      page,
      limit,
      filters,
      exportDrawerVisible,
      exportOptions,
      availableFields,
      fetchBatchOperations,
      resetFilters,
      exportData,
      hasPermission,
      importDrawerVisible,
      updateDrawerVisible,
      deduplicateDrawerVisible,
      fileList,
      beforeUpload,
      handleRemove,
      importData,
      importing,
      updateData,
      updating,
      deduplicateData,
      deduplicating,
      closeImportDrawer,
      closeUpdateDrawer,
      closeDeduplicateDrawer,
      openImportDrawer,
      openUpdateDrawer,
      openDeduplicateDrawer,
      selectedIds,
      handleSelectionChange,
      deleteSelected,

      editTask,
      downloadTask,


    };
  },
};
</script>

<style scoped>
.filter-form {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.filter-form .el-form-item {
  flex: 1 1 200px;
  max-width: 300px;
}

.el-table th, .el-table td {
  white-space: nowrap;
  overflow: visible;
}

.action-buttons {
  white-space: nowrap;
  display: flex;
  gap: 5px;
}

::v-deep .el-form-item__content {
  align-items: center !important;
  display: flex !important;
  flex: 1 !important;
  flex-wrap: nowrap !important;
  font-size: var(--font-size) !important;
  line-height: 32px !important;
  min-width: 0 !important;
  position: relative !important;
}
</style>
