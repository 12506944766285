// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import LoginView from '../views/LoginView.vue'
import DashboardView from '../views/DashboardView.vue'
import UserList from '../components/UserList.vue'
import RoleList from '../components/RoleList.vue'
import PermissionList from '../components/PermissionList.vue'
import ProFile from '../views/ProFile.vue'
import PermIp from '../components/PermIp.vue'
import CluEs from '../components/CluEs.vue'
import CluEspool from '../components/CluEspool.vue'
import BatchOperations from '../components/BatchOperations.vue'
import KsCrms from '../components/KsCrms.vue'
import KsXs from '../components/KsXs.vue'
import QqCrms from '../components/QqCrms.vue'
import QqXs from '../components/QqXs.vue'


const routes = [
    { path: '/', redirect: '/login' },
    { path: '/login', name: 'login', component: LoginView },

    {
        path: '/dashboard',
        component: DashboardView,
        children: [
            { path: 'users', name: 'users', component: UserList },
            { path: 'roles', name: 'roles', component: RoleList },
            { path: 'permissions', name: 'permissions', component: PermissionList },
            { path: 'profile', name: 'profile', component: ProFile },
            { path: 'ip', name: 'ip', component: PermIp },
            { path: 'clues', name: 'clues', component: CluEs },
            { path: 'cluespool', name: 'cluespool', component: CluEspool },
            { path: 'BatchOperations', name: 'BatchOperations', component: BatchOperations },
            { path: 'KsCrms', name: 'KsCrms', component: KsCrms },
            { path: 'KsXs', name: 'KsXs', component: KsXs },
            { path: 'QqXs', name: 'QqXs', component: QqXs },
            { path: 'QqCrms', name: 'QqCrms', component: QqCrms },
        ]
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

// 添加导航守卫
router.beforeEach((to, from, next) => {
    if (to.path !== '/login' && !store.state.token) {
        next('/login'); // 如果没有token，重定向到登录页面
    } else {
        next(); // 否则继续导航
    }
});

export default router