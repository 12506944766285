<template>
  <el-header class="top-bar">
    <div class="logo">
      <img :src="FoAdmin" alt="Logo" />
      <span>Foadmin</span>
    </div>
    
    <div class="user-profile">
      <el-avatar :src="userAvatar" size="medium"></el-avatar>
      <el-dropdown>
        <template v-slot:dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="navigateToProfile">个人资料</el-dropdown-item>
            <el-dropdown-item @click="handleLogout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </template>
        <span class="el-dropdown-link">
          {{ userName }}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
      </el-dropdown>
    </div>
  </el-header>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import axios from '../axiosInstance'
import avatar from '@/assets/avatar.png' // 引入本地图片
import foadmin from '@/assets/foadmin.png' // 引入本地图片

export default {
  setup() {
    const userName = ref('admin')
    const userAvatar = ref(avatar)
    const FoAdmin = ref(foadmin)
    const store = useStore()
    const router = useRouter()

    onMounted(async () => {
      try {
        const token = localStorage.getItem('jwt') // 从localStorage获取JWT
        const response = await axios.get('/api/apifoadmin/user/profile', {
          headers: {
            Authorization: `Bearer ${token}` // 将JWT添加到请求头
          }
        })
        if (response.data.status === 'success') {
          userName.value = response.data.data.username
          // 更新用户头像，如果后端提供的话
          userAvatar.value = response.data.data.avatar || avatar
        }
      } catch (error) {
        console.error('获取用户信息失败', error)
      }
    })

    const handleLogout = () => {
      store.dispatch('logout').then(() => {
        localStorage.removeItem('jwt') // 移除JWT
        router.push('/login')
      })
    }

    const navigateToProfile = () => {
      router.push('/dashboard/profile')
    }

    return {
      userName,
      userAvatar,
      FoAdmin,
      handleLogout,
      navigateToProfile
    }
  }
}
</script>

<style scoped>
.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  height: 64px;
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  height: 40px;
  margin-right: 10px;
}

.user-profile {
  display: flex;
  align-items: center;
}
</style>
