<template>
  <div>
    <!-- 添加权限按钮 -->
    <el-button v-if="hasPermission('add_permissions')" @click="openPermissionDrawer()">添加权限</el-button>
    <el-table :data="permissions" style="width: 100%">
      <el-table-column prop="id" label="ID" width="50"></el-table-column>
      <el-table-column prop="name" label="权限名称"></el-table-column>
      <el-table-column prop="slug" label="权限标识"></el-table-column>
      <el-table-column label="操作" width="150">
        <template v-slot:default="scope">
          <!-- 编辑权限按钮 -->
          <el-button v-if="hasPermission('edit_permissions')" @click="openPermissionDrawer(scope.row)" size="small">编辑</el-button>
          <!-- 删除权限按钮 -->
          <el-button v-if="hasPermission('delete_permissions')" @click="confirmDeletePermission(scope.row.id)" type="danger" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 权限表单抽屉 -->
    <el-drawer v-model="permissionDrawerVisible" title="权限" size="30%">
      <el-form :model="currentPermission" :rules="rules" ref="formRef">
        <el-form-item label="权限名称" prop="name">
          <el-input v-model="currentPermission.name"></el-input>
        </el-form-item>
        <el-form-item label="权限标识" prop="slug">
          <el-input v-model="currentPermission.slug"></el-input>
        </el-form-item>
      </el-form>
      <div style="text-align: right; margin-top: 20px;">
        <el-button @click="permissionDrawerVisible = false">取消</el-button>
        <el-button type="primary" @click="savePermission">保存</el-button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import axios from '../axiosInstance'
import { useStore } from 'vuex'
import { ElMessage, ElMessageBox } from 'element-plus'

export default {
  setup() {
    const store = useStore()
    const permissions = ref([])
    const permissionDrawerVisible = ref(false)
    const currentPermission = ref({ name: '', slug: '' })
    const formRef = ref(null)

    const rules = {
      name: [{ required: true, message: '请输入权限名称', trigger: 'blur' }],
      slug: [{ required: true, message: '请输入权限标识', trigger: 'blur' }]
    }

    const fetchPermissions = async () => {
      try {
        const response = await axios.get('/api/apifoadmin/permissions')
        permissions.value = response.data
      } catch (error) {
        console.error('无法获取权限列表:', error.message)
      }
    }

    const openPermissionDrawer = (permission = { name: '', slug: '' }) => {
      currentPermission.value = { ...permission }
      permissionDrawerVisible.value = true
    }

    const savePermission = async () => {
      try {
        await formRef.value.validate()
        if (currentPermission.value.id) {
          // 编辑权限
          await axios.put(`/api/apifoadmin/permissions/${currentPermission.value.id}`, currentPermission.value)
          ElMessage({
            type: 'success',
            message: '编辑成功',
          })
        } else {
          // 添加权限
          await axios.post('/api/apifoadmin/permissions', currentPermission.value)
          ElMessage({
            type: 'success',
            message: '添加成功',
          })
        }
        permissionDrawerVisible.value = false
        fetchPermissions()
      } catch (error) {
        console.error('无法保存权限:', error.message)
      }
    }

    const confirmDeletePermission = (id) => {
      ElMessageBox.confirm(
        '此操作将永久删除该权限, 是否继续?',
        '警告',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true,
        }
      )
        .then(() => {
          deletePermission(id)
          ElMessage({
            type: 'success',
            message: '删除成功',
          })
        })
        .catch(() => {
          ElMessage({
            type: 'info',
            message: '已取消删除',
          })
        })
    }

    const deletePermission = async (id) => {
      try {
        await axios.delete(`/api/apifoadmin/permissions/${id}`)
        fetchPermissions()
      } catch (error) {
        console.error('无法删除权限:', error.message)
      }
    }

    const hasPermission = (permission) => {
      return store.state.permissions.includes(permission)
    }

    onMounted(fetchPermissions)

    return {
      permissions,
      permissionDrawerVisible,
      currentPermission,
      formRef,
      rules,
      openPermissionDrawer,
      savePermission,
      confirmDeletePermission,
      hasPermission
    }
  }
}
</script>
