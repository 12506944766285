<template>
    <div class="profile-container">
      <h2>个人资料</h2>
      <el-form :model="form" @submit.prevent="updateProfile">
        <el-form-item label="--昵称">
          <el-input v-model="form.nickname"></el-input>
        </el-form-item>
        <el-form-item label="原密码">
          <el-input type="password" v-model="form.currentPassword"></el-input>
        </el-form-item>
        <el-form-item label="新密码">
          <el-input type="password" v-model="form.newPassword"></el-input>
        </el-form-item>
        <el-button type="primary" @click="updateProfile">保存</el-button>
      </el-form>
    </div>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue'
import axios from '../axiosInstance'
  
  export default {
    setup() {
      const form = ref({
        nickname: '',
        currentPassword: '',
        newPassword: ''
      })
  
      onMounted(async () => {
        try {
          const token = localStorage.getItem('jwt')
          const response = await axios.get('/api/apifoadmin/user/profile', {
            headers: {
              Authorization: `Bearer ${token}`
            }
          })
          if (response.data.status === 'success') {
            form.value.nickname = response.data.data.nickname
          }
        } catch (error) {
          console.error('获取用户信息失败', error)
        }
      })
  
      const updateProfile = async () => {
        try {
          const token = localStorage.getItem('jwt')
          const response = await axios.put('/api/apifoadmin/user/profile', form.value, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          })
          if (response.data.status === 'success') {
            alert('更新成功')
          } else {
            alert('更新失败: ' + response.data.message)
          }
        } catch (error) {
          console.error('更新用户信息失败', error)
        }
      }
  
      return {
        form,
        updateProfile
      }
    }
  }
  </script>
  
  <style scoped>
  .profile-container {
    max-width: 600px;
    margin: 20px auto;
  }
  </style>
  