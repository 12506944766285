<template>
  <div>
    <!-- 添加用户按钮 -->
    <el-button v-if="hasPermission('add_users')" @click="openUserDrawer()">添加用户</el-button>
    <el-table :data="users" style="width: 100%">
      <el-table-column prop="id" label="ID" width="50"></el-table-column>
      <el-table-column prop="username" label="用户名"></el-table-column>
      <el-table-column prop="nickname" label="昵称"></el-table-column>
      <el-table-column prop="email" label="邮箱"></el-table-column>
      <el-table-column prop="role.name" label="角色"></el-table-column>
      <el-table-column prop="ip" label="允许IP"></el-table-column>
      <el-table-column label="操作" width="150">
        <template v-slot:default="scope">
          <!-- 编辑用户按钮 -->
          <el-button v-if="hasPermission('edit_users')" @click="openUserDrawer(scope.row)" size="small">编辑</el-button>
          <!-- 删除用户按钮 -->
          <el-button v-if="hasPermission('delete_users')" @click="confirmDeleteUser(scope.row.id)" type="danger" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 用户表单抽屉 -->
    <el-drawer v-model="userDrawerVisible" title="用户" size="30%">
      <el-form :model="currentUser" :rules="rules" ref="formRef">
        <el-form-item label="用户名" prop="username">
          <el-input v-model="currentUser.username"></el-input>
        </el-form-item>
        <el-form-item label="昵称" prop="nickname">
          <el-input v-model="currentUser.nickname"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="currentUser.email"></el-input>
        </el-form-item>
        <el-form-item label="IP 地址" prop="ip">
          <el-input v-model="currentUser.ip"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password" v-if="!currentUser.id">
          <el-input v-model="currentUser.password" type="password"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="confirmPassword" v-if="!currentUser.id">
          <el-input v-model="currentUser.confirmPassword" type="password"></el-input>
        </el-form-item>
        <el-form-item label="角色" prop="role_id">
          <el-select v-model="currentUser.role_id">
            <el-option v-for="role in roles" :key="role.id" :label="role.name" :value="role.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div style="text-align: right; margin-top: 20px;">
        <el-button @click="userDrawerVisible = false">取消</el-button>
        <el-button type="primary" @click="saveUser">保存</el-button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import axios from '../axiosInstance'
import { useStore } from 'vuex'
import { ElMessage, ElMessageBox } from 'element-plus'

export default {
  setup() {
    const store = useStore()
    const users = ref([])
    const roles = ref([])
    const userDrawerVisible = ref(false)
    const currentUser = ref({ username: '', nickname: '', email: '', ip: '', role_id: null, password: '', confirmPassword: '' })
    const formRef = ref(null)

    const rules = {
      username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
      nickname: [{ required: true, message: '请输入昵称', trigger: 'blur' }],
      email: [{ required: true, message: '请输入邮箱', trigger: 'blur', type: 'email' }],
      password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
      confirmPassword: [
        { required: true, message: '请确认密码', trigger: 'blur' },
        {
          validator: (rule, value, callback) => {
            if (value !== currentUser.value.password) {
              callback(new Error('两次输入的密码不一致'))
            } else {
              callback()
            }
          },
          trigger: 'blur'
        }
      ],
      role_id: [{ required: true, message: '请选择角色', trigger: 'change' }]
    }

    const fetchUsers = async () => {
      try {
        const response = await axios.get('/api/apifoadmin/users')
        users.value = response.data
      } catch (error) {
        console.error('无法获取用户列表:', error.message)
      }
    }

    const fetchRoles = async () => {
      try {
        const response = await axios.get('/api/apifoadmin/roles')
        roles.value = response.data
      } catch (error) {
        console.error('无法获取角色列表:', error.message)
      }
    }

    const openUserDrawer = (user = { username: '', nickname: '', email: '', ip: '', role_id: null }) => {
      currentUser.value = { ...user, password: '', confirmPassword: '' }
      userDrawerVisible.value = true
    }

    const saveUser = async () => {
      try {
        await formRef.value.validate()
        if (currentUser.value.id) {
          // 编辑用户（不修改密码）
          const { id, username, nickname, email, ip, role_id } = currentUser.value
          await axios.put(`/api/apifoadmin/users/${id}`, { username, nickname, email, ip, role_id })
          ElMessage({
            type: 'success',
            message: '编辑成功',
          })
        } else {
          // 添加用户
          await axios.post('/api/apifoadmin/users', currentUser.value)
          ElMessage({
            type: 'success',
            message: '添加成功',
          })
        }
        userDrawerVisible.value = false
        fetchUsers()
      } catch (error) {
        console.error('无法保存用户:', error.message)
      }
    }

    const confirmDeleteUser = (id) => {
      ElMessageBox.confirm(
        '此操作将永久删除该用户, 是否继续?',
        '警告',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true,
        }
      )
        .then(() => {
          deleteUser(id)
          ElMessage({
            type: 'success',
            message: '删除成功',
          })
        })
        .catch(() => {
          ElMessage({
            type: 'info',
            message: '已取消删除',
          })
        })
    }

    const deleteUser = async (id) => {
      try {
        await axios.delete(`/api/apifoadmin/users/${id}`)
        fetchUsers()
      } catch (error) {
        console.error('无法删除用户:', error.message)
      }
    }

    const hasPermission = (permission) => {
      return store.state.permissions.includes(permission)
    }

    onMounted(() => {
      fetchUsers()
      fetchRoles()
    })

    return {
      users,
      roles,
      userDrawerVisible,
      currentUser,
      formRef,
      rules,
      openUserDrawer,
      saveUser,
      confirmDeleteUser,
      hasPermission
    }
  }
}
</script>
