<template>
  <el-container>
    <TopBar />
    <el-container>
      <SideMenu />
      <el-main class="main-content">
        <router-view />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import TopBar from './TopBar.vue'
import SideMenu from './SideMenu.vue'

export default {
  components: {
    TopBar,
    SideMenu
  }
}
</script>

<style>
body,
html,
#app {
  margin: 0;
  height: 100%;
}

.el-container {
  height: 100%;
}

.main-content {
  margin-left: 200px;
  padding: 20px;
  overflow-y: auto; /* 保持内容可滚动 */
  margin-top: 64px; /* 确保主内容从顶栏下方开始 */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: transparent transparent; /* Firefox */
}

.main-content::-webkit-scrollbar {
  width: 8px; /* 控制滚动条的宽度 */
  background-color: transparent; /* 背景色透明 */
}

.main-content::-webkit-scrollbar-thumb {
  background-color: transparent; /* 滚动条滑块颜色透明 */
}

.el-header,
.top-bar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.side-menu {
  position: fixed;
  top: 64px; /* 确保侧边栏从顶栏下方开始 */
  width: 200px;
  height: calc(100vh - 64px); /* 减去顶部栏的高度 */
}

.el-main {
  padding: 20px;
  margin-top: 64px; /* 确保主内容从顶栏下方开始 */
}
</style>
