<template>
    <p style="color: #172b4d; font-weight: 900; font-size: 24px;">
          <el-icon><Operation /></el-icon> 快手线索管理
        </p>
    
      <div>
        <!-- 筛选表单 -->
        <el-form :inline="true" :model="filters" class="filter-form">
          <el-form-item v-if="hasPermission('xs-cjsj')"  label="创建时间">
            <el-date-picker
              v-model="filters.created_at"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
          </el-form-item>
    
          <el-form-item v-if="hasPermission('xs-xsmc')"  label="线索名称">
            <el-input v-model="filters.clue_name" placeholder="请输入线索名称"></el-input>
          </el-form-item>
    
          <el-form-item v-if="hasPermission('phone_number')" label="手机号码">
            <el-input v-model="filters.phone_number" placeholder="请输入手机号码"></el-input>
          </el-form-item>
    
          <el-form-item  v-if="hasPermission('country_code')" label="国际区号">
            <el-input v-model="filters.country_code" placeholder="请输入国际区号"></el-input>
          </el-form-item>
    
          <el-form-item v-if="hasPermission('user_name')" label="姓名">
            <el-input v-model="filters.user_name" placeholder="请输入姓名"></el-input>
          </el-form-item>
    
          <el-form-item v-if="hasPermission('user_location')" label="用户位置">
            <el-input v-model="filters.user_location" placeholder="请输入用户位置"></el-input>
          </el-form-item>
    
          <el-form-item v-if="hasPermission('gender')" label="性别">
            <el-select v-model="filters.gender" placeholder="请选择性别">
              <el-option label="男" value="男"></el-option>
              <el-option label="女" value="女"></el-option>
              <el-option label="未知" value="未知"></el-option>
            </el-select>
          </el-form-item>
    
          <el-form-item v-if="hasPermission('conversion_status')" label="转化状态">
            <el-select v-model="filters.conversion_status" placeholder="请选择转化状态">
              <el-option label="已转化" value="已转化"></el-option>
              <el-option label="未转化" value="未转化"></el-option>
            </el-select>
          </el-form-item>
    
          <el-form-item v-if="hasPermission('follow_up_status')" label="跟进状态">
            <el-select v-model="filters.follow_up_status" placeholder="请选择跟进状态">
              <el-option label="已跟进" value="已跟进"></el-option>
              <el-option label="未跟进" value="未跟进"></el-option>
            </el-select>
          </el-form-item>
    
          <el-form-item v-if="hasPermission('WhatsApp')" label="WhatsApp">
            <el-select v-model="filters.whatsapp_status" placeholder="请选择WhatsApp状态">
              <el-option label="已开通" value="已开通"></el-option>
              <el-option label="未开通" value="未开通"></el-option>
            </el-select>
          </el-form-item>
    
          <el-form-item v-if="hasPermission('ICICI_Bank')" label="ICICI Bank">
            <el-select v-model="filters.icici_bank_status" placeholder="请选择ICICI Bank状态">
              <el-option label="已开通" value="已开通"></el-option>
              <el-option label="未开通" value="未开通"></el-option>
            </el-select>
          </el-form-item>
    
          <el-form-item v-if="hasPermission('CaratLane')" label="CaratLane">
            <el-select v-model="filters.carat_lane_status" placeholder="请选择CaratLane状态">
              <el-option label="已开通" value="已开通"></el-option>
              <el-option label="未开通" value="未开通"></el-option>
            </el-select>
          </el-form-item>
    
          <el-form-item v-if="hasPermission('Telegram')" label="Telegram">
            <el-select v-model="filters.telegram_status" placeholder="请选择Telegram状态">
              <el-option label="已开通" value="已开通"></el-option>
              <el-option label="未开通" value="未开通"></el-option>
            </el-select>
          </el-form-item>
    
          <el-form-item v-if="hasPermission('Axis_Bank')" label="Axis Bank">
            <el-select v-model="filters.axis_bank_status" placeholder="请选择Axis Bank状态">
              <el-option label="已开通" value="已开通"></el-option>
              <el-option label="未开通" value="未开通"></el-option>
            </el-select>
          </el-form-item>
    
          <el-form-item v-if="hasPermission('HDFC_Bank')" label="HDFC Bank">
            <el-select v-model="filters.hdfc_bank_status" placeholder="请选择HDFC Bank状态">
              <el-option label="已开通" value="已开通"></el-option>
              <el-option label="未开通" value="未开通"></el-option>
            </el-select>
          </el-form-item>
    
          <el-form-item v-if="hasPermission('SbiBank')" label="SbiBank">
            <el-select v-model="filters.sbiban_status" placeholder="请选择SbiBank状态">
              <el-option label="已开通" value="已开通"></el-option>
              <el-option label="未开通" value="未开通"></el-option>
            </el-select>
          </el-form-item>
    
          <el-form-item v-if="hasPermission('Kotak_Bank')" label="Kotak Bank">
            <el-select v-model="filters.kotak_bank_status" placeholder="请选择Kotak Bank状态">
              <el-option label="已开通" value="已开通"></el-option>
              <el-option label="未开通" value="未开通"></el-option>
            </el-select>
          </el-form-item>
    
          <el-form-item v-if="hasPermission('last_contact_date')" label="最后跟进时间">
            <el-date-picker
              v-model="filters.last_contact_date"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
          </el-form-item>
    
          <el-form-item v-if="hasPermission('next_contact_date')" label="下次联系时间">
            <el-date-picker
              v-model="filters.next_contact_date"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
          </el-form-item>
    
          <el-form-item v-if="hasPermission('source')" label="来源">
            <el-input v-model="filters.source" placeholder="请输入来源"></el-input>
          </el-form-item>
    
          <el-form-item v-if="hasPermission('wechat')" label="微信号">
            <el-input v-model="filters.wechat" placeholder="请输入微信号"></el-input>
          </el-form-item>
    
          <el-form-item v-if="hasPermission('registration_status')" label="注册状态">
            <el-select v-model="filters.registration_status" placeholder="请选择注册状态">
              <el-option label="已注册" value="已注册"></el-option>
              <el-option label="未注册" value="未注册"></el-option>
            </el-select>
          </el-form-item>
    
          <el-form-item v-if="hasPermission('creator')" label="创建者">
            <el-input v-model="filters.creator" placeholder="请输入创建者"></el-input>
          </el-form-item>
    
          <el-form-item v-if="hasPermission('responsible_person')" label="负责人">
            <el-input v-model="filters.responsible_person" placeholder="请输入负责人"></el-input>
          </el-form-item>
    
          <el-form-item>
            <el-button type="primary" v-if="hasPermission('xiansuochaxun')" @click="fetchClues">查询</el-button>
            <el-button v-if="hasPermission('xiansuochongzhi')"  @click="resetFilters">重置</el-button>
            <el-button type="info" v-if="hasPermission('create_cluelp')" @click="moveToCluePool" :disabled="selectedRows.length === 0">放入线索池</el-button>
            <el-button type="primary" v-if="hasPermission('create_clue')" @click="importDrawerVisible = true">导入</el-button>
          </el-form-item>
    
          <el-form-item style="margin-left: auto;">
            
            <el-button type="primary" v-if="hasPermission('add_clue')" @click="openClueDrawer()">新增</el-button>
            <el-button type="danger" v-if="hasPermission('batchDelete_clue')" @click="batchDelete" :disabled="selectedRows.length === 0">批量删除</el-button>
            <el-button type="warning" v-if="hasPermission('exportDrawerVisible_clue')" @click="exportDrawerVisible = true">导出</el-button>
               
  
          </el-form-item>
        </el-form>
    
        <!-- 数据表格 -->
        <el-table
          :data="clues"
          style="width: 100%"
          :header-cell-style="{ whiteSpace: 'nowrap' }"
          :cell-style="{ whiteSpace: 'nowrap' }"
          @selection-change="handleSelectionChange"
          v-loading="loading"
          element-loading-text="加载中"
          element-loading-spinner="el-icon-loading"
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column prop="id" label="编号" width="80" align="center"></el-table-column>
          <el-table-column prop="created_at" v-if="hasPermission('xs-cjsj')" label="创建时间" width="180"></el-table-column>
          <!--<el-table-column prop="created_year_month" label="创建年月" width="120"></el-table-column>-->
          <el-table-column prop="clue_name" v-if="hasPermission('xs-xsmc')" label="线索名称" width="200"></el-table-column>
          <el-table-column prop="user_name" v-if="hasPermission('user_name')" label="姓名" width="150"></el-table-column>
          <el-table-column prop="phone_number" v-if="hasPermission('phone_number')" label="手机号码" width="150"></el-table-column>
          <el-table-column prop="country_code" v-if="hasPermission('country_code')" label="国际区号" width="100"></el-table-column>
          
          <el-table-column prop="user_location" v-if="hasPermission('user_location')" label="用户位置" width="200"></el-table-column>
          <el-table-column prop="gender" v-if="hasPermission('gender')" label="性别" width="100" align="center"></el-table-column>
  
         <el-table-column prop="conversion_status" v-if="hasPermission('conversion_status')" label="转化状态" width="150" align="center">
          <template v-slot="scope">
         <span :class="getStatusClass(scope.row.conversion_status)">
        {{ scope.row.conversion_status }}
         </span>
        </template>
         </el-table-column>
          <el-table-column prop="follow_up_status" v-if="hasPermission('follow_up_status')" label="跟进状态" width="150" align="center">
            <template v-slot="scope">
         <span :class="getStatusClass(scope.row.follow_up_status)">
        {{ scope.row.follow_up_status }}
         </span>
        </template>
          </el-table-column>
          <el-table-column v-if="hasPermission('WhatsApp')" prop="whatsapp_status" label="WhatsApp" width="150" align="center">
          <template v-slot="scope">
         <span :class="getStatusClass(scope.row.whatsapp_status)">
        {{ scope.row.whatsapp_status }}
         </span>
        </template>
      </el-table-column>
          <el-table-column v-if="hasPermission('ICICI_Bank')" prop="icici_bank_status" label="ICICI Bank" width="150" align="center">
            <template v-slot="scope">
         <span :class="getStatusClass(scope.row.icici_bank_status)">
        {{ scope.row.icici_bank_status }}
         </span>
        </template>
          </el-table-column>
          <el-table-column v-if="hasPermission('CaratLane')" prop="carat_lane_status" label="CaratLane" width="150" align="center">
            <template v-slot="scope">
         <span :class="getStatusClass(scope.row.carat_lane_status)">
        {{ scope.row.carat_lane_status }}
         </span>
        </template>
          </el-table-column>
          <el-table-column v-if="hasPermission('Telegram')" prop="telegram_status" label="Telegram" width="150" align="center">
            <template v-slot="scope">
         <span :class="getStatusClass(scope.row.telegram_status)">
        {{ scope.row.telegram_status }}
         </span>
        </template>
          </el-table-column>
          <el-table-column v-if="hasPermission('Axis_Bank')" prop="axis_bank_status" label="Axis Bank" width="150" align="center">
            <template v-slot="scope">
         <span :class="getStatusClass(scope.row.axis_bank_status)">
        {{ scope.row.axis_bank_status }}
         </span>
        </template>
          </el-table-column>
          <el-table-column v-if="hasPermission('HDFC_Bank')" prop="hdfc_bank_status" label="HDFC Bank" width="150" align="center">
            <template v-slot="scope">
         <span :class="getStatusClass(scope.row.hdfc_bank_status)">
        {{ scope.row.hdfc_bank_status }}
         </span>
        </template>
          </el-table-column>
          <el-table-column v-if="hasPermission('SbiBank')" prop="sbiban_status" label="SbiBank" width="150" align="center">
            <template v-slot="scope">
         <span :class="getStatusClass(scope.row.sbiban_status)">
        {{ scope.row.sbiban_status }}
         </span>
        </template>
          </el-table-column>
          <el-table-column v-if="hasPermission('Kotak_Bank')" prop="kotak_bank_status" label="Kotak Bank" width="150" align="center">
            <template v-slot="scope">
         <span :class="getStatusClass(scope.row.kotak_bank_status)">
        {{ scope.row.kotak_bank_status }}
         </span>
        </template>
          </el-table-column>
          <el-table-column prop="last_contact_date" v-if="hasPermission('last_contact_date')" label="最后跟进时间" width="180"></el-table-column>
          <el-table-column prop="next_contact_date" v-if="hasPermission('next_contact_date')" label="下次联系时间" width="180"></el-table-column>
          <el-table-column prop="source" v-if="hasPermission('source')" label="来源" width="150"></el-table-column>
          <el-table-column prop="ad_source_type" v-if="hasPermission('source1')" label="流量线索来源" width="150"></el-table-column>
          <el-table-column v-if="hasPermission('wechat')" prop="wechat" label="微信号" width="150"></el-table-column>
          <el-table-column prop="registration_status" v-if="hasPermission('registration_status')" label="注册状态" width="150" align="center">
    <template v-slot="scope">
      <span :class="getStatusClass(scope.row.registration_status)">
        {{ scope.row.registration_status }}
      </span>
    </template>
  </el-table-column>
  
          <el-table-column prop="remarks" v-if="hasPermission('remarks')" label="备注" width="200"></el-table-column>
          <el-table-column prop="creator" v-if="hasPermission('creator')" label="创建者" width="150"></el-table-column>
          <el-table-column prop="responsible_person" v-if="hasPermission('responsible_person')" label="负责人" width="200"></el-table-column>
          <el-table-column label="操作" width="180" align="center">
            <template v-slot="scope">
              <div class="action-buttons">
                <el-button v-if="hasPermission('edit_clue')" @click="openClueDrawer(scope.row)" size="small">编辑</el-button>
                <el-button type="danger" v-if="hasPermission('delete_clue')" @click="deleteClue(scope.row.id)" size="small">删除</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
    
        <!-- 分页 -->
        <el-pagination
          background
          layout="prev, pager, next, total"
          :total="total"
          :page-size="limit"
          v-model:current-page="page"
          @current-change="fetchClues"
        ></el-pagination>
    
        <!-- 线索弹出框 -->
        <el-drawer
          title="线索管理"
          :model-value="clueDrawerVisible"
          size="50%"
          direction="rtl"
          @close="clueDrawerVisible = false"
        >
          <el-form :model="currentClue" :rules="rules" ref="clueFormRef" label-width="100px">
    
            <el-form-item label="线索名称" prop="clue_name">
              <el-input v-model="currentClue.clue_name"></el-input>
            </el-form-item>
    
            <el-form-item label="手机号码" prop="phone_number">
              <el-input v-model="currentClue.phone_number"></el-input>
            </el-form-item>
    
            <el-form-item label="国际区号" prop="country_code">
              <el-input v-model="currentClue.country_code"></el-input>
            </el-form-item>
    
            <el-form-item label="姓名" prop="user_name">
              <el-input v-model="currentClue.user_name"></el-input>
            </el-form-item>
    
            <el-form-item label="用户位置" prop="user_location">
              <el-input v-model="currentClue.user_location"></el-input>
            </el-form-item>
    
            <el-form-item label="性别" prop="gender">
              <el-select v-model="currentClue.gender">
                <el-option label="男" value="男"></el-option>
                <el-option label="女" value="女"></el-option>
                <el-option label="未知" value="未知"></el-option>
              </el-select>
            </el-form-item>
    
            <el-form-item label="转化状态" prop="conversion_status">
              <el-select v-model="currentClue.conversion_status">
                <el-option label="已转化" value="已转化"></el-option>
                <el-option label="未转化" value="未转化"></el-option>
              </el-select>
            </el-form-item>
    
            <el-form-item label="跟进状态" prop="follow_up_status">
              <el-select v-model="currentClue.follow_up_status">
                <el-option label="已跟进" value="已跟进"></el-option>
                <el-option label="未跟进" value="未跟进"></el-option>
              </el-select>
            </el-form-item>
    
            <el-form-item v-if="hasPermission('WhatsApp')" label="WhatsApp" prop="whatsapp_status">
              <el-select v-model="currentClue.whatsapp_status">
                <el-option label="已开通" value="已开通"></el-option>
                <el-option label="未开通" value="未开通"></el-option>
              </el-select>
            </el-form-item>
    
            <el-form-item v-if="hasPermission('ICICI_Bank')" label="ICICI Bank" prop="icici_bank_status">
              <el-select v-model="currentClue.icici_bank_status">
                <el-option label="已开通" value="已开通"></el-option>
                <el-option label="未开通" value="未开通"></el-option>
              </el-select>
            </el-form-item>
    
            <el-form-item v-if="hasPermission('CaratLane')" label="CaratLane" prop="carat_lane_status">
              <el-select v-model="currentClue.carat_lane_status">
                <el-option label="已开通" value="已开通"></el-option>
                <el-option label="未开通" value="未开通"></el-option>
              </el-select>
            </el-form-item>
    
            <el-form-item v-if="hasPermission('Telegram')" label="Telegram" prop="telegram_status">
              <el-select v-model="currentClue.telegram_status">
                <el-option label="已开通" value="已开通"></el-option>
                <el-option label="未开通" value="未开通"></el-option>
              </el-select>
            </el-form-item>
    
            <el-form-item v-if="hasPermission('Axis_Bank')" label="Axis Bank" prop="axis_bank_status">
              <el-select v-model="currentClue.axis_bank_status">
                <el-option label="已开通" value="已开通"></el-option>
                <el-option label="未开通" value="未开通"></el-option>
              </el-select>
            </el-form-item>
    
            <el-form-item v-if="hasPermission('HDFC_Bank')" label="HDFC Bank" prop="hdfc_bank_status">
              <el-select v-model="currentClue.hdfc_bank_status">
                <el-option label="已开通" value="已开通"></el-option>
                <el-option label="未开通" value="未开通"></el-option>
              </el-select>
            </el-form-item>
    
            <el-form-item v-if="hasPermission('SbiBank')" label="SbiBank" prop="sbiban_status">
              <el-select v-model="currentClue.sbiban_status">
                <el-option label="已开通" value="已开通"></el-option>
                <el-option label="未开通" value="未开通"></el-option>
              </el-select>
            </el-form-item>
    
            <el-form-item v-if="hasPermission('Kotak_Bank')" label="Kotak Bank" prop="kotak_bank_status">
              <el-select v-model="currentClue.kotak_bank_status">
                <el-option label="已开通" value="已开通"></el-option>
                <el-option label="未开通" value="未开通"></el-option>
              </el-select>
            </el-form-item>
    
            <el-form-item label="最后跟进时间" prop="last_contact_date">
              <el-date-picker v-model="currentClue.last_contact_date" type="date" placeholder="请选择最后跟进时间"></el-date-picker>
            </el-form-item>
    
            <el-form-item label="下次联系时间" prop="next_contact_date">
              <el-date-picker v-model="currentClue.next_contact_date" type="date" placeholder="请选择下次联系时间"></el-date-picker>
            </el-form-item>
    
            <el-form-item v-if="hasPermission('source')" label="来源" prop="source">
              <el-input v-model="currentClue.source"></el-input>
            </el-form-item>
    
            <el-form-item v-if="hasPermission('wechat')" label="微信号" prop="wechat">
              <el-input v-model="currentClue.wechat"></el-input>
            </el-form-item>
    
            <el-form-item label="注册状态" prop="registration_status">
              <el-select v-model="currentClue.registration_status">
                <el-option label="已注册" value="已注册"></el-option>
                <el-option label="未注册" value="未注册"></el-option>
              </el-select>
            </el-form-item>
    
            <el-form-item label="备注" prop="remarks">
              <el-input v-model="currentClue.remarks"></el-input>
            </el-form-item>
    
            <el-form-item v-if="hasPermission('assignDialogVisible_clue')" label="负责人" prop="responsible_person">
              <el-select v-model="currentClue.responsible_person" multiple placeholder="请选择负责人">
                <el-option v-for="user in users" :key="user.id" :label="user.username" :value="user.username"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div style="text-align: right; margin-top: 20px;">
            <el-button @click="clueDrawerVisible = false">取消</el-button>
            <el-button type="primary" @click="saveClue">保存</el-button>
          </div>
        </el-drawer>
  
  
  
  
   <!-- 导出配置对话框 -->
   <el-drawer
      title="导出线索"
      :model-value="exportDrawerVisible"
      size="50%"
      direction="rtl"
      @close="exportDrawerVisible = false"
    >
      <el-form :model="exportOptions">
        <el-form-item label="选择字段导出范围">
          <el-checkbox-group v-model="exportOptions.fields">
           
           
            <el-checkbox v-for="field in filteredFields" :label="field.value" :key="field.value">
      {{ field.label }}
    </el-checkbox>




          </el-checkbox-group>
        </el-form-item>
  
        <el-form-item label="选择导出文件类型">
          <el-radio-group v-model="exportOptions.file_type">
            <el-radio label="xls">xls</el-radio>
            <el-radio label="csv">csv</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
  
      <div style="text-align: right; margin-top: 20px;">
        <el-button @click="exportDrawerVisible = false">取消</el-button>
        <el-button type="primary" @click="exportData">导出</el-button>
      </div>
    </el-drawer>
  
  
  
    <!--导入线索-->
  <el-drawer
      title="导入线索"
      :model-value="importDrawerVisible"
      size="30%"
      direction="rtl"
      @close="importDrawerVisible = false"
    >
  
  
   <!-- 添加说明和下载链接 -->
   <div style="margin-bottom: 20px;">
        <p style="color: #b5b5b5; font-size: 13px;">
         请按照数据模板的格式准备要导入的数据。 
          <a href="https://serve.cdnusdt.com/xls/线索导入模板.xlsx" download>点击下载《线索导入模板》</a>
  
        </p>
        <p style="color: #b5b5b5; font-size: 13px;">导入文件勿超过2MB（约10,000条数据）</p>
      </div>
  
  
    <el-upload
    ref="upload"
    action=""
    :auto-upload="false"
    v-model:file-list="fileList"
    :before-upload="beforeUpload"
    :on-remove="handleRemove"
  >
    <template #trigger>
      <el-button type="primary">选择文件</el-button>
    </template>
    <template #tip>
      <div class="el-upload__tip">支持 xls 和 csv 格式</div>
    </template>
  </el-upload>
  
      <div style="text-align: right; margin-top: 20px;">
        <el-button @click="importDrawerVisible = false">取消</el-button>
        <el-button type="primary" @click="importData">导入</el-button>
      </div>
  </el-drawer>
  
      </div>
    </template>
    
    <script>
    import { ref, onMounted, nextTick, computed } from 'vue';
    import { useStore } from 'vuex';
    import axios from '../axiosInstance';
    import { ElMessage, ElMessageBox } from 'element-plus';
    
    import { Operation } from '@element-plus/icons-vue';
    
    export default {
      components: {
        Operation,
      },
    
      setup() {
        const clues = ref([]);
        const total = ref(0);
        const page = ref(1);
        const limit = ref(15);
        const selectedRows = ref([]);
        const loading = ref(false);
        const clueDrawerVisible = ref(false);
        const currentClue = ref({
          clue_name: '',
          phone_number: '',
          country_code: '',
          user_name: '',
          user_location: '',
          gender: '未知',
          conversion_status: '未转化',
          follow_up_status: '未跟进',
          whatsapp_status: '未开通',
          icici_bank_status: '未开通',
          carat_lane_status: '未开通',
          telegram_status: '未开通',
          axis_bank_status: '未开通',
          hdfc_bank_status: '未开通',
          sbiban_status: '未开通',
          kotak_bank_status: '未开通',
          last_contact_date: '',
          next_contact_date: '',
          source: '',
          wechat: '',
          registration_status: '未注册',
          remarks: '',
          responsible_person: [],
          creator: '',
        });
        const users = ref([]);
        const store = useStore();
        const clueFormRef = ref(null);
        const filters = ref({
          created_at: [],
          clue_name: '',
          phone_number: '',
          country_code: '',
          user_name: '',
          user_location: '',
          gender: '',
          conversion_status: '',
          follow_up_status: '',
          whatsapp_status: '',
          icici_bank_status: '',
          carat_lane_status: '',
          telegram_status: '',
          axis_bank_status: '',
          hdfc_bank_status: '',
          sbiban_status: '',
          kotak_bank_status: '',
          last_contact_date: [],
          next_contact_date: [],
          source: '',
          wechat: '',
          registration_status: '',
          creator: '',
          responsible_person: ''
        });
  
  
  
        const fetchClues = async () => {
          loading.value = true;
          try {
            const response = await axios.get('/api/apifoadmin/clueskscrm', {
              params: {
                page: page.value,
                limit: limit.value,
                filter: filters.value,
              },
            });
            clues.value = response.data.data;
            total.value = response.data.pagination.total;
          } catch (error) {
            ElMessage.error('无法获取线索列表: ' + error.message);
          } finally {
            loading.value = false;
          }
        };
  

        const filteredFields = computed(() => {
      return availableFields.filter(field => hasPermission(field.permission));
    });



        const exportDrawerVisible = ref(false);
      const exportOptions = ref({
        fields: [
          'id', 'created_at', 'created_year_month', 'clue_name', 'phone_number', 'country_code',
          'user_name', 'user_location', 'gender', 'conversion_status', 'follow_up_status', 
          'whatsapp_status', 'icici_bank_status', 'carat_lane_status', 'telegram_status', 
          'axis_bank_status', 'hdfc_bank_status', 'sbiban_status', 'kotak_bank_status', 
          'last_contact_date', 'next_contact_date', 'source', 'wechat', 'registration_status', 
          'remarks', 'creator', 'responsible_person'
        ],
        file_type: 'xls',
      });
  
      const availableFields = [
  { label: '编号', value: 'id', permission: 'xs-id' },
  { label: '创建时间', value: 'created_at', permission: 'xs-cjsj' },
  { label: '创建年月', value: 'created_year_month', permission: 'xs-cjym' },
  { label: '线索名称', value: 'clue_name', permission: 'xs-xsmc' },
  { label: '手机号码', value: 'phone_number', permission: 'phone_number' },
  { label: '国际区号', value: 'country_code', permission: 'country_code' },
  { label: '姓名', value: 'user_name', permission: 'user_name' },
  { label: '用户位置', value: 'user_location', permission: 'user_location' },
  { label: '性别', value: 'gender', permission: 'gender' },
  { label: '转化状态', value: 'conversion_status', permission: 'conversion_status' },
  { label: '跟进状态', value: 'follow_up_status', permission: 'follow_up_status' },
  { label: 'WhatsApp 状态', value: 'whatsapp_status', permission: 'WhatsApp' },
  { label: 'ICICI Bank 状态', value: 'icici_bank_status', permission: 'ICICI_Bank' },
  { label: 'CaratLane 状态', value: 'carat_lane_status', permission: 'CaratLane' },
  { label: 'Telegram 状态', value: 'telegram_status', permission: 'Telegram' },
  { label: 'Axis Bank 状态', value: 'axis_bank_status', permission: 'Axis_Bank' },
  { label: 'HDFC Bank 状态', value: 'hdfc_bank_status', permission: 'HDFC_Bank' },
  { label: 'SbiBank 状态', value: 'sbiban_status', permission: 'SbiBank' },
  { label: 'Kotak Bank 状态', value: 'kotak_bank_status', permission: 'Kotak_Bank' },
  { label: '最近联系时间', value: 'last_contact_date', permission: 'last_contact_date' },
  { label: '下次联系时间', value: 'next_contact_date', permission: 'next_contact_date' },
  { label: '来源', value: 'source', permission: 'source' },
  { label: '微信号', value: 'wechat', permission: 'wechat' },
  { label: '注册状态', value: 'registration_status', permission: 'registration_status' },
  { label: '备注', value: 'remarks', permission: 'remarks' },
  { label: '创建者', value: 'creator', permission: 'creator' },
  { label: '负责人', value: 'responsible_person', permission: 'responsible_person' },
];

  
   
  /*
  const exportData = async () => {
    try {
      let selectedIds = selectedRows.value.map(row => row.id);
  
      // 如果没有选择数据，则自动选择当前页的所有数据
      if (selectedIds.length === 0) {
        selectedIds = clues.value.map(row => row.id);
      }
  
      const response = await axios({
        url: '/api/apifoadmin/cluesdc/export', // 请求的后端地址
        method: 'POST',
        responseType: 'blob', // 设置响应类型为 blob
        data: {
          fields: exportOptions.value.fields,
          file_type: exportOptions.value.file_type,
          ids: selectedIds.length > 0 ? selectedIds : null, // 如果有选中数据，传递选中的 ID 列表，否则传递 null
        },
      });
  
      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const link = document.createElement('a');
      const url = window.URL.createObjectURL(blob);
  
      link.href = url;
      link.setAttribute('download', `clues_export.${exportOptions.value.file_type}`);
      document.body.appendChild(link);
      link.click();
  
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
  
      exportDrawerVisible.value = false;
      ElMessage.success('数据导出成功');
    } catch (error) {
      ElMessage.error('导出数据失败: ' + error.message);
    }
  };
  */



  const exportData = async () => {
  try {
    let selectedIds = selectedRows.value.map(row => row.id);

    // 如果没有选择数据，则自动选择当前页的所有数据
    if (selectedIds.length === 0) {
      selectedIds = clues.value.map(row => row.id);
    }

    // 使用有权限的字段
    const fieldsToExport = filteredFields.value.map(field => field.value);

    const response = await axios({
      url: '/api/apifoadmin/cluesdc/export', // 请求的后端地址
      method: 'POST',
      responseType: 'blob', // 设置响应类型为 blob
      data: {
        fields: fieldsToExport, // 只提交有权限的字段
        file_type: exportOptions.value.file_type,
        ids: selectedIds.length > 0 ? selectedIds : null, // 如果有选中数据，传递选中的 ID 列表，否则传递 null
      },
    });

    const blob = new Blob([response.data], { type: response.headers['content-type'] });
    const link = document.createElement('a');
    const url = window.URL.createObjectURL(blob);

    link.href = url;
    link.setAttribute('download', `clues_export.${exportOptions.value.file_type}`);
    document.body.appendChild(link);
    link.click();

    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);

    exportDrawerVisible.value = false;
    ElMessage.success('数据导出成功');
  } catch (error) {
    ElMessage.error('导出数据失败: ' + error.message);
  }
};


  
  
  const moveToCluePool = async () => {
    if (selectedRows.value.length > 0) {
      try {
        await ElMessageBox.confirm(
          '此操作将把选中的线索放入线索池, 是否继续?',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'info',
            center: true,
          }
        );
        const ids = selectedRows.value.map(row => row.id);
        await axios.post('/api/apifoadmin/cluesxsc/moveToCluePool', { ids });
        fetchClues();
        ElMessage.success('线索已成功放入线索池');
      } catch (error) {
        if (error !== 'cancel') {
          ElMessage.error('迁移线索失败: ' + error.message);
        }
      }
    }
  };
  
  
  const importDrawerVisible = ref(false);
  const fileList = ref([]);
  // 上传前的文件验证
  const beforeUpload = (file) => {
    console.log('Uploading file:', file); // 调试输出
    const isXls = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const isCsv = file.type === 'text/csv';
    if (!isXls && !isCsv) {
      ElMessage.error('只能上传 xls 或 csv 格式的文件');
      return false;
    }
    return true;
  };
  
  
  
  // 处理文件移除
  const handleRemove = (file) => {
    fileList.value = fileList.value.filter(f => f.uid !== file.uid);
  };
  
  // 执行导入操作
  const importData = async () => {
    console.log('fileList:', fileList.value); // 调试打印fileList内容
    
    if (!fileList.value || fileList.value.length === 0) {
      ElMessage.error('请先选择文件');
      return;
    }
  
    const formData = new FormData();
    formData.append('file', fileList.value[0].raw);
  
    try {
      await axios.post('/api/apifoadmin/cluesdr/import', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      ElMessage.success('数据导入成功');
      importDrawerVisible.value = false;
      fetchClues(); // 刷新线索列表
    } catch (error) {
      ElMessage.error('数据导入失败: ' + error.message);
    }
  };
  
  
  
    
        const resetFilters = () => {
          filters.value = {
            created_at: [],
            clue_name: '',
            phone_number: '',
            country_code: '',
            user_name: '',
            user_location: '',
            gender: '',
            conversion_status: '',
            follow_up_status: '',
            whatsapp_status: '',
            icici_bank_status: '',
            carat_lane_status: '',
            telegram_status: '',
            axis_bank_status: '',
            hdfc_bank_status: '',
            sbiban_status: '',
            kotak_bank_status: '',
            last_contact_date: [],
            next_contact_date: [],
            source: '',
            wechat: '',
            registration_status: '',
            creator: '',
            responsible_person: ''
          };
          fetchClues();
          ElMessage.success('筛选条件已重置');
        };
    
        const openClueDrawer = async (clue = null) => {
          if (clue) {
            currentClue.value = { ...clue, responsible_person: clue.responsible_person ? clue.responsible_person.split(',') : [] };
          } else {
            currentClue.value = {
              clue_name: '',
              phone_number: '',
              country_code: '',
              user_name: '',
              user_location: '',
              gender: '未知',
              conversion_status: '未转化',
              follow_up_status: '未跟进',
              whatsapp_status: '未开通',
              icici_bank_status: '未开通',
              carat_lane_status: '未开通',
              telegram_status: '未开通',
              axis_bank_status: '未开通',
              hdfc_bank_status: '未开通',
              sbiban_status: '未开通',
              kotak_bank_status: '未开通',
              last_contact_date: '',
              next_contact_date: '',
              source: '',
              wechat: '',
              registration_status: '未注册',
              remarks: '',
              responsible_person: [],
              creator: '',
            };
          }
          clueDrawerVisible.value = true;
    
          await nextTick();
        };
    
        const saveClue = async () => {
          await nextTick();
    
          if (!clueFormRef.value) {
            console.error('clueFormRef is not defined or is null');
            return;
          }
    
          try {
            await clueFormRef.value.validate();
    
            const token = localStorage.getItem('jwt');
            const response = await axios.get('/api/apifoadmin/user/profile', {
              headers: {
                Authorization: `Bearer ${token}`
              }
            });
            if (response.data.status === 'success') {
              currentClue.value.creator = response.data.data.username;
            } else {
              console.error('获取用户信息失败');
              return;
            }
    
            const clueData = {
              ...currentClue.value,
              responsible_person: currentClue.value.responsible_person.join(','), 
            };
            if (currentClue.value.id) {
              await axios.put(`/api/apifoadmin/clues/${currentClue.value.id}`, clueData);
              ElMessage.success('线索编辑成功');
            } else {
              await axios.post('/api/apifoadmin/clues', clueData);
              ElMessage.success('线索添加成功');
            }
            clueDrawerVisible.value = false;
            fetchClues();
          } catch (error) {
            ElMessage.error('保存线索失败: ' + error.message);
          }
        };
    
        const deleteClue = async (id) => {
          try {
            await ElMessageBox.confirm(
              '此操作将永久删除该线索, 是否继续?',
              '警告',
              {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                center: true,
              }
            );
            await axios.delete(`/api/apifoadmin/clues/${id}`);
            fetchClues();
            ElMessage.success('线索删除成功');
          } catch (error) {
            if (error !== 'cancel') {
              ElMessage.error('无法删除线索: ' + error.message);
            }
          }
        };
    
        const batchDelete = async () => {
          if (selectedRows.value.length > 0) {
            try {
              await ElMessageBox.confirm(
                '此操作将永久删除选中的线索, 是否继续?',
                '警告',
                {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning',
                  center: true,
                }
              );
              const ids = selectedRows.value.map(row => row.id);
              await axios.post('/api/apifoadmin/cluest/batchDelete', { ids });
              fetchClues();
              ElMessage.success('批量删除成功');
            } catch (error) {
              if (error !== 'cancel') {
                ElMessage.error('批量删除失败: ' + error.message);
              }
            }
          }
        };
    
    
  
  
        const getStatusClass = (status) => {
    switch (status) {
      case '已开通':
      case '已注册':
      case '已转化':
      case '已跟进':
        return 'status-success'; // 绿色背景，表示成功状态
      case '未开通':
      case '未注册':
      case '未转化':
      case '未跟进':
        return 'status-danger'; // 红色背景，表示失败状态
      case '未知':
      default:
        return 'status-unknown'; // 橙色背景，表示未知状态
    }
  };
  
  
    
        const handleSelectionChange = (selection) => {
          selectedRows.value = selection;
        };
    
        const fetchUsers = async () => {
          try {
            const response = await axios.get('/api/apifoadmin/userst');
            users.value = response.data.data;
          } catch (error) {
            ElMessage.error('无法获取用户列表: ' + error.message);
          }
        };
    
        const hasPermission = (permission) => {
          return store.state.permissions.includes(permission);
        }
    
        onMounted(() => {
         
          fetchClues();
          fetchUsers();
        });
    
  
  
  
        return {
          clues,
          total,
          page,
          limit,
          filters,
          selectedRows,
          loading,
          clueDrawerVisible,
          currentClue,
          users,
          fetchClues,
          resetFilters,
          openClueDrawer,
          saveClue,
          deleteClue,
          batchDelete,
          handleSelectionChange,
          clueFormRef,
          hasPermission,
          exportDrawerVisible,
        exportOptions,
        availableFields,
        exportData,
        moveToCluePool,
  
        importDrawerVisible,
    fileList,
    beforeUpload,
    handleRemove,
    importData,
  
    getStatusClass,

    filteredFields,
  
        };
      },
    };
    </script>
    
    <style scoped>
    .filter-form {
      margin-bottom: 20px;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }
    
    .filter-form .el-form-item {
      flex: 1 1 200px;
      max-width: 300px;
    }
    
    .el-table th, .el-table td {
      white-space: nowrap;
      overflow: visible;
    }
    
    .action-buttons {
      white-space: nowrap;
      display: flex;
      gap: 5px;
    }
  
  
    ::v-deep .el-form-item__content {
      align-items: center !important;
      display: flex !important;
      flex: 1 !important;
      flex-wrap: nowrap !important;
      font-size: var(--font-size) !important;
      line-height: 32px !important;
      min-width: 0 !important;
      position: relative !important;
  }
  
  
  
  
  
  
  .status-success {
      background-color: #8bc34a; /* 已开通、已注册、已转化、已跟进的背景颜色 */
      color: #fff;
      padding: 3px 8px;
      border-radius: 4px;
      display: inline-block;
      text-align: center;
  }
  
  .status-danger {
      background-color: #f44336; /* 未开通、未注册、未转化、未跟进的背景颜色 */
      color: #fff;
      padding: 3px 8px;
      border-radius: 4px;
      display: inline-block;
      text-align: center;
  }
  
  .status-unknown {
      background-color: #ff9800; /* 未知状态的背景颜色（如果需要） */
      color: #fff;
      padding: 3px 8px;
      border-radius: 4px;
      display: inline-block;
      text-align: center;
  }
  
    </style>