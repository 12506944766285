<template>
  <div>
    <el-button v-if="hasPermission('add_roles')" @click="openRoleDrawer()">添加角色</el-button>
    <el-table :data="roles" style="width: 100%">
      <el-table-column prop="id" label="ID" width="50"></el-table-column>
      <el-table-column prop="name" label="角色名称"></el-table-column>
      <el-table-column prop="description" label="描述"></el-table-column>
      <el-table-column label="操作" width="150">
        <template v-slot:default="scope">
          <el-button v-if="hasPermission('edit_roles')" @click="openRoleDrawer(scope.row)" size="small">编辑</el-button>
          <el-button v-if="hasPermission('delete_roles')" @click="deleteRole(scope.row.id)" type="danger" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-drawer v-model="roleDrawerVisible" title="角色" size="30%">
      <el-form :model="currentRole" :rules="rules" ref="formRef">
        <el-form-item label="角色名称" prop="name">
          <el-input v-model="currentRole.name"></el-input>
        </el-form-item>
        <el-form-item label="描述" prop="description">
          <el-input v-model="currentRole.description"></el-input>
        </el-form-item>
        <el-form-item label="分配权限" prop="permissions">
          <el-checkbox-group v-model="currentRole.permissions">
            <el-checkbox v-for="permission in allPermissions" :key="permission.id" :label="permission.id">
              {{ permission.name }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <div style="text-align: right; margin-top: 20px;">
        <el-button @click="roleDrawerVisible = false">取消</el-button>
        <el-button type="primary" @click="saveRole">保存</el-button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import axios from '../axiosInstance'
import { useStore } from 'vuex'
import { ElMessage, ElMessageBox } from 'element-plus'

export default {
  setup() {
    const store = useStore()
    const roles = ref([])
    const allPermissions = ref([])
    const roleDrawerVisible = ref(false)
    const currentRole = ref({ name: '', description: '', permissions: [] })
    const formRef = ref(null)

    const rules = {
      name: [{ required: true, message: '请输入角色名称', trigger: 'blur' }],
      description: [{ required: false, message: '请输入描述', trigger: 'blur' }],
      permissions: [{ type: 'array', required: true, message: '请选择至少一个权限', trigger: 'change' }]
    }

    const fetchRoles = async () => {
      try {
        const response = await axios.get('/api/apifoadmin/roles')
        roles.value = response.data
      } catch (error) {
        console.error('无法获取角色列表:', error.message)
      }
    }

    const fetchPermissions = async () => {
      try {
        const response = await axios.get('/api/apifoadmin/permissions')
        allPermissions.value = response.data
      } catch (error) {
        console.error('无法获取权限列表:', error.message)
      }
    }

    const openRoleDrawer = (role = { name: '', description: '', permissions: [] }) => {
      currentRole.value = {
        ...role,
        permissions: role.permissions ? role.permissions.map((p) => p.id) : [],
      }
      roleDrawerVisible.value = true
    }

    const saveRole = async () => {
      try {
        await formRef.value.validate()
        const roleData = {
          name: currentRole.value.name,
          description: currentRole.value.description,
          permissions: currentRole.value.permissions,
        }
        if (currentRole.value.id) {
          await axios.put(`/api/apifoadmin/roles/${currentRole.value.id}`, roleData)
          ElMessage({
            type: 'success',
            message: '编辑成功',
          })
        } else {
          await axios.post('/api/apifoadmin/roles', roleData)
          ElMessage({
            type: 'success',
            message: '添加成功',
          })
        }
        roleDrawerVisible.value = false
        fetchRoles()
      } catch (error) {
        console.error('无法保存角色:', error.message)
      }
    }

    const deleteRole = async (id) => {
      ElMessageBox.confirm(
        '此操作将永久删除该角色, 是否继续?',
        '警告',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true,
        }
      )
        .then(async () => {
          try {
            await axios.delete(`/api/apifoadmin/roles/${id}`)
            fetchRoles()
            ElMessage({
              type: 'success',
              message: '删除成功',
            })
          } catch (error) {
            console.error('无法删除角色:', error.message)
          }
        })
        .catch(() => {
          ElMessage({
            type: 'info',
            message: '已取消删除',
          })
        })
    }

    const hasPermission = (permission) => {
      return store.state.permissions.includes(permission)
    }

    onMounted(() => {
      fetchRoles()
      fetchPermissions()
    })

    return {
      roles,
      allPermissions,
      roleDrawerVisible,
      currentRole,
      formRef,
      rules,
      openRoleDrawer,
      saveRole,
      deleteRole,
      hasPermission
    }
  }
}
</script>
