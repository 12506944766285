<template>
  <div>
    <!--<el-menu :default-active="activeIndex" class="el-menu-vertical-demo" @select="handleSelect">
      <el-menu-item index="users" v-if="hasPermission('view_users')">用户管理</el-menu-item>
      <el-menu-item index="roles" v-if="hasPermission('view_roles')">角色管理</el-menu-item>
      <el-menu-item index="permissions" v-if="hasPermission('view_permissions')">权限管理</el-menu-item>
      <el-menu-item @click="handleLogout">退出登录</el-menu-item>
    </el-menu>-->
    <router-view></router-view>
  </div>

</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  name: 'DashboardView',
  setup() {
    const activeIndex = ref('users')
    const store = useStore()
    const router = useRouter()

    const handleSelect = (index) => {
      activeIndex.value = index
      router.push(`/dashboard/${index}`)
    }

    const handleLogout = () => {
      store.dispatch('logout').then(() => {
        router.push('/login')
      })
    }

    const hasPermission = (permission) => {
      return store.state.permissions.includes(permission);
    };

    return {
      activeIndex,
      handleSelect,
      handleLogout,
      hasPermission
    }
  }
}
</script>
