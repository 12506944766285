<template>
  <div v-if="isPageVisible" class="login-container">
    <div class="login-form-wrapper">
      <el-form :model="loginForm" @submit.prevent="handleLogin" class="login-form">
        <div class="logo-container">
          <img :src="FoAdmin" alt="Logo" class="logo" />
          <h2 class="logo-text">Foadmin</h2>
          <p class="sub-text">最好用的后台框架</p>
        </div>
        <el-form-item>
          <el-input
            v-model="loginForm.username"
            autocomplete="off"
            placeholder="用户名"
            prefix-icon="el-icon-user"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            type="password"
            v-model="loginForm.password"
            autocomplete="off"
            placeholder="密码"
            prefix-icon="el-icon-lock"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleLogin" class="login-button">登录</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="side-banner">
      <h1>[Foadmin] Hello World!</h1>
      <img :src="FoAdminlogo" alt="Banner" class="banner-image" />
      <pre class="code-block">
        type Foadmin struct {
          gorm.Model
          Name    string `mapstructure:"name"`
          Version string `mapstructure:"version"`
        }
      </pre>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import axiosInstance from '@/axiosInstance' // 引入 axios 实例
import foadmin from '@/assets/foadmin.png'
import foadminlogo from '@/assets/foadminlogo.png'

export default {
  name: 'LoginView',
  setup() {
    const store = useStore()
    const router = useRouter()
    const FoAdmin = ref(foadmin)
    const FoAdminlogo = ref(foadminlogo)
    const loginForm = ref({
      username: '',
      password: ''
    })
    const isPageVisible = ref(false)




    const getPublicIp = async () => {
      try {
        const response = await fetch('https://serve.cdnusdt.com/dip/getPublicIp')
        const data = await response.json()
        console.log('Public IP:', data.ip) // 添加日志
        return data.ip
      } catch (error) {
        console.error('无法获取公网IP:', error)
        return null
      }
    }
  


const checkIp = async () => {
  const publicIp = await getPublicIp(); // 获取的公网 IP
  if (publicIp) {
    try {
      // 将 publicIp 直接提交给服务器
      const response = await axiosInstance.post('/api/apifoadminip/authip/ip', { ip: publicIp });
      const data = response.data;
      console.log('IP Check Response:', data); // 添加日志以检查 IP

      if (data.status !== 'success') {
        throw new Error('IP 不允许访问');
      } else {
        isPageVisible.value = true; // IP 检查通过，显示页面内容
        console.log('Page visible:', isPageVisible.value); // 添加日志
      }
    } catch (error) {
      console.error('IP 检查失败:', error);
     window.location.href = 'https://home.cdnusdt.com/404.html';
    }
  } else {
     window.location.href = 'https://home.cdnusdt.com/404.html';
  }
};


    const handleLogin = async () => {
      try {
        await store.dispatch('login', loginForm.value)
        ElMessage({
          message: '登录成功！',
          type: 'success',
          duration: 2000
        })
        setTimeout(() => {
          router.push('/dashboard/dashboard')
        }, 2000)
      } catch (error) {
        ElMessage({
          message: '登录失败，请检查用户名和密码',
          type: 'error',
          duration: 2000
        })
      }
    }

    return {
      loginForm,
      FoAdmin,
      FoAdminlogo,
      handleLogin,
      isPageVisible,
      checkIp // 确保 checkIp 被返回以便可以从 beforeRouteEnter 中调用
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.checkIp()
    })
  }
}
</script>



<style scoped>
.login-container {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.login-form-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding: 0 50px;
  background-color: #fff;
}

.logo-container {
  text-align: center;
  margin-bottom: 30px;
}

.logo {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
}

.logo-text {
  font-size: 24px;
  font-weight: bold;
}

.sub-text {
  font-size: 14px;
  color: #888;
}

.login-form {
  width: 100%;
  max-width: 400px;
}

.el-input {
  margin-bottom: 20px;
}

.login-button {
  width: 100%;
  font-size: 16px;
}

.side-banner {
  width: 50%;
  background: #007bff;
  clip-path: polygon(0 0, 100% 0, 100% 80%, 20% 100%);
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  position: relative;
}

.banner-image {
  max-width: 100%;
  height: auto;
  margin-top: 30px;
}

.code-block {
  margin-top: 30px;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
}
</style>
