<template>
    <div>
      <p style="color: #172b4d; font-weight: 900; font-size: 24px;">
        <el-icon><Cpu /></el-icon> 广告账户管理
      </p>
  

      <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 20px;">
      <div>
        推送地址: <span style="color: #333;">https://serve.cdnusdt.com/api/apifoadmin/txcrm</span>
      </div>
      <div>
        Token: <span style="color: red">E3CEB5881A0A1FDAAD01296D7554868D</span>
      </div>
      <div>
        请求参数 密钥: <span style="color: blue;">E3CEB5881A0A1FDAAD01296D7554868D</span>
      </div>
      <div>
        姓名: <span style="color: red">user_name</span>
      </div>
      <div>
        电话号: <span style="color: red">phone_number</span>
      </div>
      <div>
        广告账户id: <span style="color: red">source</span>
      </div>

      <div>
        线索用户归属地城市: <span style="color: red">user_location</span>
      </div>
      <div>
        流量来源渠道: <span style="color: red">ad_source_type</span>
      </div>

    </div>



      <el-form :inline="true" :model="filters" class="filter-form">
        <!-- 广告账户名称过滤 -->
        <el-form-item label="广告账户名称">
          <el-input v-model="filters.ad_account_name" placeholder="请输入广告账户名称"></el-input>
        </el-form-item>
  
        <!-- 查询与重置按钮 -->
        <el-form-item>
          <el-button type="primary" @click="fetchAdAccounts">查询</el-button>
          <el-button @click="resetFilters">重置</el-button>
        </el-form-item>
  
        <el-form-item>
          <el-button type="primary" @click="openCreateAdAccountDrawer">创建广告账户</el-button>
        </el-form-item>
      </el-form>
  
      <el-table :data="adAccounts" style="width: 100%">
        <el-table-column prop="id" label="广告账户ID" width="100"></el-table-column>
        <el-table-column prop="name" label="广告账户名称"></el-table-column>
        <el-table-column prop="ad_id" label="腾讯ID"></el-table-column>
        <el-table-column prop="created_at" label="创建时间"></el-table-column>
        <el-table-column prop="updated_at" label="更新时间"></el-table-column>
        <el-table-column label="操作" width="180">
          <template #default="{ row }">
            <el-button type="primary" @click="openEditAdAccountDrawer(row.id)" size="mini">编辑</el-button>
            <el-button type="danger" @click="deleteAdAccount(row.id)" size="mini">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
  
     <el-pagination
        background
        layout="prev, pager, next, total"
        :total="total"
        :page-size="limit"
        v-model:current-page="page"
        @current-change="fetchAdAccounts"
      ></el-pagination>


    



  
      <!-- 创建广告账户对话框 -->
      <el-drawer
        title="创建广告账户"
        :model-value="createAdAccountDrawerVisible"
        size="30%"
        direction="rtl"
        @close="closeCreateAdAccountDrawer"
      >
        <el-form :model="currentAdAccount">
          <el-form-item label="广告账户名称">
            <el-input v-model="currentAdAccount.name"></el-input>
          </el-form-item>

          <el-form-item label="--广告账户ID">
            <el-input v-model="currentAdAccount.ad_id"></el-input>
          </el-form-item>

  
          <el-form-item label="用户管理">
            <div v-for="(user, index) in currentAdAccount.users" :key="index" style="margin-bottom: 10px;">
              <el-input v-model="user.user_name" placeholder="用户名" style="width: 45%; margin-right: 10px;"></el-input>
              <el-input-number v-model="user.data_count" placeholder="数量" style="width: 40%;"></el-input-number>
              <!--<el-button type="danger" @click="removeUser(index)" icon="el-icon-minus" style="margin-left: 10px;"></el-button>-->
              <el-button type="danger" @click="removeUser(index)" style="margin-left: 10px;">
    <el-icon><Remove /></el-icon>
  </el-button>
            </div>
            <el-button type="primary" @click="addUser"><el-icon><Plus /></el-icon>添加用户</el-button>
          </el-form-item>
        </el-form>
  
        <div style="text-align: right; margin-top: 20px;">
          <el-button @click="closeCreateAdAccountDrawer">取消</el-button>
          <el-button type="primary" @click="createAdAccount">保存</el-button>
        </div>
      </el-drawer>
  
      <!-- 编辑广告账户对话框 -->
      <el-drawer
        title="编辑广告账户"
        :model-value="editAdAccountDrawerVisible"
        size="30%"
        direction="rtl"
        @close="closeEditAdAccountDrawer"
      >
        <el-form :model="currentAdAccount">
          <el-form-item label="广告账户名称">
            <el-input v-model="currentAdAccount.name"></el-input>
          </el-form-item>

          <el-form-item label="--腾讯账户ID">
            <el-input v-model="currentAdAccount.ad_id"></el-input>
          </el-form-item>

  
          <el-form-item label="用户管理">
            <div v-for="(user, index) in currentAdAccount.users" :key="index" style="margin-bottom: 10px;">
              <el-input v-model="user.user_name" placeholder="用户名" style="width: 45%; margin-right: 10px;"></el-input>
              <el-input-number v-model="user.data_count" placeholder="数量" style="width: 40%;"></el-input-number>
              <!--<el-button type="danger" @click="removeUser(index)" icon="el-icon-minus" style="margin-left: 10px;"></el-button>-->


<el-button type="danger" @click="removeUser(index)" style="margin-left: 10px;">
    <el-icon><Remove /></el-icon>
  </el-button>


            </div>
            <el-button type="primary" @click="addUser"><el-icon><Plus /></el-icon>添加用户</el-button>
          </el-form-item>
        </el-form>
  
        <div style="text-align: right; margin-top: 20px;">
          <el-button @click="closeEditAdAccountDrawer">取消</el-button>
          <el-button type="primary" @click="saveEditAdAccount">保存</el-button>
        </div>
      </el-drawer>
    </div>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue';
  import axios from '../axiosInstance';
  import { ElMessage, ElMessageBox } from 'element-plus';
  import { Cpu, Remove, Plus } from '@element-plus/icons-vue';
  
  export default {
    components: {
      Cpu,
      Remove,
      Plus,
    },
    setup() {
      const adAccounts = ref([]);
      const total = ref(0);
      const page = ref(1);
      const limit = ref(40);
      const filters = ref({
        ad_account_name: '',
      });
      const createAdAccountDrawerVisible = ref(false);
      const editAdAccountDrawerVisible = ref(false);
      const currentAdAccount = ref({
        id: null,
        name: '',
        users: [], // 包含用户数据的数组
      });
  
 
   


      const fetchAdAccounts = async () => {
  try {
    console.log("Current page:", page.value);  // 添加这行日志查看当前页码
    const response = await axios.get('/api/apifoadmin/ad-accountsqq', {
      params: {
        page: page.value,  // 确保这里传递了正确的当前页码
        limit: limit.value,
        filter: filters.value,
      },
    });
    adAccounts.value = response.data.data;
    total.value = response.data.pagination.total;
  } catch (error) {
    ElMessage.error('无法获取广告账户列表: ' + error.message);
  }
};


      
  
      const resetFilters = () => {
        filters.value = {
          ad_account_name: '',
        };
        fetchAdAccounts();
        ElMessage.success('筛选条件已重置');
      };
  
      const openCreateAdAccountDrawer = () => {
        currentAdAccount.value = { id: null, name: '', users: [] };
        createAdAccountDrawerVisible.value = true;
      };
  



const openEditAdAccountDrawer = async (id) => {
  try {
    const response = await axios.get(`/api/apifoadmin/ad-accountsqq/${id}`);
    // 查找匹配的广告账户
    const adAccountData = response.data.data.find(item => item.id === id);

    if (adAccountData) {
      // 绑定用户数据到表单
      currentAdAccount.value = {
        id: adAccountData.id,
        name: adAccountData.name,
        ad_id: adAccountData.ad_id,
        users: adAccountData.userData || [] // 将userData数组赋值给表单的users字段
      };

      editAdAccountDrawerVisible.value = true;
    } else {
      ElMessage.error('未找到该广告账户');
    }
  } catch (error) {
    ElMessage.error('无法加载广告账户数据: ' + error.message);
  }
};







  
      const createAdAccount = async () => {
        try {
          await axios.post('/api/apifoadmin/ad-accountsqq', currentAdAccount.value);
          ElMessage.success('广告账户创建成功');
          createAdAccountDrawerVisible.value = false;
          fetchAdAccounts();
        } catch (error) {
          ElMessage.error('创建广告账户失败: ' + error.message);
        }
      };
  
      const saveEditAdAccount = async () => {
        try {
          await axios.put(`/api/apifoadmin/ad-accountsqq/${currentAdAccount.value.id}`, currentAdAccount.value);
          ElMessage.success('广告账户更新成功');
          editAdAccountDrawerVisible.value = false;
          fetchAdAccounts();
        } catch (error) {
          ElMessage.error('保存广告账户失败: ' + error.message);
        }
      };
  
      const deleteAdAccount = (id) => {
        ElMessageBox.confirm('此操作将永久删除该广告账户, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true,
        }).then(async () => {
          try {
            await axios.delete(`/api/apifoadmin/ad-accountsqq/${id}`);
            ElMessage.success('广告账户删除成功');
            fetchAdAccounts();
          } catch (error) {
            ElMessage.error('删除广告账户失败: ' + error.message);
          }
        }).catch(() => {
          ElMessage.info('已取消删除');
        });
      };
  
      const addUser = () => {
        currentAdAccount.value.users.push({ user_name: '', data_count: 0 });
      };
  
      const removeUser = (index) => {
        currentAdAccount.value.users.splice(index, 1);
      };
  
      const closeCreateAdAccountDrawer = () => {
        createAdAccountDrawerVisible.value = false;
      };
  
      const closeEditAdAccountDrawer = () => {
        editAdAccountDrawerVisible.value = false;
      };
      onMounted(() => {
        fetchAdAccounts();
      });
  
      
      return {
        adAccounts,
        total,
        page,
        limit,
        filters,
        createAdAccountDrawerVisible,
        editAdAccountDrawerVisible,
        currentAdAccount,
        fetchAdAccounts,
        resetFilters,
        openCreateAdAccountDrawer,
        openEditAdAccountDrawer,
        createAdAccount,
        saveEditAdAccount,
        deleteAdAccount,
        addUser,
        removeUser,
        closeCreateAdAccountDrawer,
        closeEditAdAccountDrawer,

      };
    },
  };
  </script>
  
  <style scoped>
  .filter-form {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .el-table th, .el-table td {
    white-space: nowrap;
    overflow: visible;
  }



  </style>
  