<template>
  <div>
    <!-- 添加IP按钮 -->
    <el-button v-if="hasPermission('add_ip')" @click="openIpDrawer()">添加IP</el-button>
    <el-table :data="ipRecords" style="width: 100%">
      <el-table-column prop="id" label="ID" width="50"></el-table-column>
      <el-table-column prop="user_id" label="用户ID"></el-table-column>
      <el-table-column prop="username" label="用户名"></el-table-column>
      <el-table-column prop="ip" label="IP地址"></el-table-column>
      <el-table-column prop="timestamp" label="时间"></el-table-column>
      <el-table-column label="操作" width="150">
        <template v-slot:default="scope">
          <!-- 编辑IP按钮 -->
          <el-button v-if="hasPermission('edit_ip')" @click="openIpDrawer(scope.row)" size="small">编辑</el-button>
          <!-- 删除IP按钮 -->
          <el-button v-if="hasPermission('delete_ip')" @click="confirmDeleteIp(scope.row.id)" type="danger" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- IP表单抽屉 -->
    <el-drawer v-model="ipDrawerVisible" title="IP" size="30%">
      <el-form :model="currentIp" :rules="rules" ref="formRef">
        <el-form-item label="用户ID" prop="user_id">
          <el-input v-model="currentIp.user_id"></el-input>
        </el-form-item>
        <el-form-item label="用户名" prop="username">
          <el-input v-model="currentIp.username"></el-input>
        </el-form-item>
        <el-form-item label="IP地址" prop="ip">
          <el-input v-model="currentIp.ip"></el-input>
        </el-form-item>
      </el-form>
      <div style="text-align: right; margin-top: 20px;">
        <el-button @click="ipDrawerVisible = false">取消</el-button>
        <el-button type="primary" @click="saveIp">保存</el-button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import axios from '../axiosInstance'
import { useStore } from 'vuex'
import { ElMessage, ElMessageBox } from 'element-plus'

export default {
  setup() {
    const store = useStore()
    const ipRecords = ref([])
    const ipDrawerVisible = ref(false)
    const currentIp = ref({ user_id: '', username: '', ip: '' })
    const formRef = ref(null)

    const rules = {
      user_id: [{ required: true, message: '请输入用户ID', trigger: 'blur' }],
      username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
      ip: [{ required: true, message: '请输入IP地址', trigger: 'blur' }]
    }

    const fetchIpRecords = async () => {
      try {
        const response = await axios.get('/api/apifoadmin/ip')
        ipRecords.value = response.data
      } catch (error) {
        console.error('无法获取IP列表:', error.message)
      }
    }

    const openIpDrawer = (ipRecord = { user_id: '', username: '', ip: '' }) => {
      currentIp.value = { ...ipRecord }
      ipDrawerVisible.value = true
    }

    const saveIp = async () => {
      try {
        await formRef.value.validate()
        if (currentIp.value.id) {
          // 编辑IP
          await axios.put(`/api/apifoadmin/ip/${currentIp.value.id}`, currentIp.value)
          ElMessage({
            type: 'success',
            message: '编辑成功',
          })
        } else {
          // 添加IP
          await axios.post('/api/apifoadmin/ip', currentIp.value)
          ElMessage({
            type: 'success',
            message: '添加成功',
          })
        }
        ipDrawerVisible.value = false
        fetchIpRecords()
      } catch (error) {
        console.error('无法保存IP:', error.message)
      }
    }

    const confirmDeleteIp = (id) => {
      ElMessageBox.confirm(
        '此操作将永久删除该IP, 是否继续?',
        '警告',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true,
        }
      )
        .then(() => {
          deleteIp(id)
          ElMessage({
            type: 'success',
            message: '删除成功',
          })
        })
        .catch(() => {
          ElMessage({
            type: 'info',
            message: '已取消删除',
          })
        })
    }

    const deleteIp = async (id) => {
      try {
        await axios.delete(`/api/apifoadmin/ip/${id}`)
        fetchIpRecords()
      } catch (error) {
        console.error('无法删除IP:', error.message)
      }
    }

    const hasPermission = (permission) => {
      // 检查用户是否具有指定的权限
      return store.state.permissions.includes(permission)
    }

    onMounted(fetchIpRecords)

    return {
      ipRecords,
      ipDrawerVisible,
      currentIp,
      formRef,
      rules,
      openIpDrawer,
      saveIp,
      confirmDeleteIp,
      hasPermission
    }
  }
}
</script>
