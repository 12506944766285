// src/axiosInstance.js
import axios from 'axios'
import router from './router'
import store from './store'

const instance = axios.create({
    baseURL: 'https://serve.cdnusdt.com' // 请根据你的后端地址配置 
})


instance.interceptors.request.use(config => {
    const token = localStorage.getItem('token')
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
}, error => {
    return Promise.reject(error)
})

instance.interceptors.response.use(response => {
    return response
}, error => {
    if (error.response && error.response.status === 401) {
        store.dispatch('logout').then(() => {
            router.push('/login')
        })
    }
    return Promise.reject(error)
})

export default instance